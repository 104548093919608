import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DialogType } from '~dialogs/model/dialog-data-types';

@Component({
  selector: 'dialog-icon',
  templateUrl: './dialog-icon.component.html',
  styleUrls: ['./dialog-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogIconComponent {
  @Input() type: DialogType;

  readonly modalTypes = DialogType;

  getBgColorClass(): string {
    switch (this.type) {
      case DialogType.INFO:
        return 'bg-teal-100';
      case DialogType.WARNING:
        return 'bg-orange-100';
      case DialogType.ERROR:
        return 'bg-red-100';
      case DialogType.SUCCESS:
        return 'bg-green-100';

      default:
        return 'bg-teal-100';
    }
  }
}
