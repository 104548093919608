import { APP_INITIALIZER, ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { SentryErrorHandler } from '@sentry/angular';
import { LanguageService } from '~core/services/language.service';
import { LanguageCode } from '~core/model2/language';
import { environment } from '~environments/environment';

class DummyErrorHandler extends SentryErrorHandler {
  constructor() {
    super();
  }
  handleError(error: unknown) {
    console.log('SENTRY REPORTING:', error);
  }
}

class CustomErrorHandler extends SentryErrorHandler {
  private errorHandler: ErrorHandler;
  private readonly sentryEnabled: boolean;

  constructor(private languageService: LanguageService) {
    super({});
    this.sentryEnabled = !environment.sentry.disabled;
    languageService.currentLanguageCode.subscribe((langCode) => {
      if (this.sentryEnabled) {
        this.errorHandler = this.initializeHandler(langCode);
      }
    });
  }

  handleError(error: unknown) {
    if (!this.errorHandler) {
      this.errorHandler = this.initializeHandler();
    }

    this.errorHandler.handleError(error);
  }

  private initializeHandler(langCode?: LanguageCode): ErrorHandler {
    if (this.sentryEnabled) {
      const lang = langCode || this.languageService.currentLanguageCode.getValue() || LanguageCode.EN;
      return Sentry.createErrorHandler({
        showDialog: true,
        dialogOptions: {
          lang
        }
      });
    }

    return new DummyErrorHandler();
  }
}

@NgModule({
  imports: [HttpClientModule],
  providers: [
    {
      provide: ErrorHandler,
      useClass: CustomErrorHandler,
      deps: [LanguageService]
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    }
  ]
})
export class CoreModule {
  /**
   *
   *
   * Constructor
   *
   *
   *
   * @param _domSanitizer
   * @param _matIconRegistry
   * @param parentModule
   */
  constructor(
    private _domSanitizer: DomSanitizer,
    private _matIconRegistry: MatIconRegistry,
    @Optional() @SkipSelf() parentModule?: CoreModule
  ) {
    // Do not allow multiple injections
    if (parentModule) {
      throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
    }

    Sentry.setContext('app', {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      app_version: environment.appVersion
    });

    // Register icon sets
    this.runAndRetry(() => {
      this._matIconRegistry.addSvgIconSet(
        this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-twotone.svg')
      );
    });
    this.runAndRetry(() => {
      this._matIconRegistry.addSvgIconSetInNamespace(
        'mat_outline',
        this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-outline.svg')
      );
    });
    this.runAndRetry(() => {
      this._matIconRegistry.addSvgIconSetInNamespace(
        'iconsmind',
        this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/iconsmind.svg')
      );
    });
    this.runAndRetry(() => {
      this._matIconRegistry.addSvgIconSetInNamespace(
        'dripicons',
        this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/dripicons.svg')
      );
    });
    this.runAndRetry(() => {
      this._matIconRegistry.addSvgIconSetInNamespace(
        'feather',
        this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/feather.svg')
      );
    });
    this.runAndRetry(() => {
      this._matIconRegistry.addSvgIconSetInNamespace(
        'heroicons_outline',
        this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-outline.svg')
      );
    });
    this.runAndRetry(() => {
      this._matIconRegistry.addSvgIconSetInNamespace(
        'heroicons_solid',
        this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-solid.svg')
      );
    });
  }

  private runAndRetry(fn: () => void) {
    try {
      fn();
    } catch (e) {
      setTimeout(() => {
        try {
          fn();
        } catch (err) {
          // doi nothing
        }
      }, 100);
    }
  }
}
