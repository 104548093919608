<!-- Navigation -->
<treo-vertical-navigation
  class="theme-dark navigation"
  [appearance]="'classic'"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'"
  [navigation]="navigationItems"
  [opened]="!isScreenSmall"
>
  <div treoVerticalNavigationContentHeader>
    <!-- Actions -->
    <div class="actions">
      <!-- Logo -->
      <div class="logo">
        <img src="../../../../assets/media/logos/favicon.ico" />
      </div>

      <!-- Spacer -->
      <div class="spacer"></div>

      <!-- Lang selector -->
      <div>
        <button mat-button [matMenuTriggerFor]="languageMenu">
          <div class="flex items-center">
            <img
              *ngIf="!!selectedLanguage"
              class="mr-4 w-8"
              [src]="'assets/icons/flags/' + selectedLanguage.flag + '.svg'"
            />
            <span class="text-uppercase">{{ selectedLanguage?.code }}</span>
          </div>
        </button>

        <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">
          <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
            <span class="flex items-center">
              <img class="mr-4 w-8" [src]="'assets/icons/flags/' + lang.flag + '.svg'" />
              <span class="iso text-black">{{ lang.title }}</span>
            </span>
          </button>
        </mat-menu>
      </div>

      <!-- Spacer -->
      <div class="spacer"></div>

      <!-- User -->
      <user [showAvatar]="false" [email]="user?.email" [userType]="user?.type"></user>
    </div>

    <!-- User -->
    <div class="user">
      <div class="avatar">
        <img src="../../../../assets/images/avatars/user.svg" />
      </div>
      <div class="info">
        <div class="name flex justify-center">
          {{ username }}
          <label *ngIf="user?.maidenName">({{ user?.maidenName }})</label>
        </div>
        <div class="email text-secondary">{{ user?.email }}</div>
      </div>
    </div>
  </div>
  <div treoVerticalNavigationFooter>
    <app-version></app-version>
  </div>
</treo-vertical-navigation>

<!-- Wrapper -->
<div class="wrapper">
  <div class="sticky-top">
    <button class="navigation-toggle-button" mat-icon-button (click)="toggleNavigation('mainNavigation')">
      <mat-icon [svgIcon]="'menu'"></mat-icon>
    </button>
  </div>
  <div class="content">
    <maintenance-alert></maintenance-alert>
    <router-outlet></router-outlet>
  </div>
</div>
