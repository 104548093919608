import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MaintenanceAlertModule } from 'app//maintenance-alert/maintenance-alert.module';
import { TreoNavigationModule } from '~treo/components/navigation';
import { UserModule } from '~layout/user/user.module';
import { SharedModule } from '~shared/shared.module';
import { ClassyLayoutComponent } from '~layout/layouts/classy/classy.component';
import { AppVersionComponent } from '~layout/layouts/classy/version/app-version.component';

@NgModule({
  declarations: [ClassyLayoutComponent, AppVersionComponent],
  imports: [HttpClientModule, RouterModule, TreoNavigationModule, UserModule, SharedModule, MaintenanceAlertModule],
  exports: [ClassyLayoutComponent]
})
export class ClassyLayoutModule {}
