import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Printable } from 'app//print/Printable';

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  isPrinting = false;

  constructor(private router: Router) {}

  print<T>(printable: Printable<T>): void {
    this.isPrinting = true;
    const state = printable.getRouteState() || {};
    const queryParams = printable.getQueryParams() || {};
    this.router.navigate(
      [
        '/',
        {
          outlets: {
            print: ['print', ...printable.getOutletRouteParts()]
          }
        }
      ],
      {
        state,
        replaceUrl: true,
        queryParams: {
          ...queryParams
        }
      }
    );
  }

  onDataReady = (): void => {
    setTimeout(() => {
      window.print();
      this.isPrinting = false;
      this.router.navigate([{ outlets: { print: null } }], {
        replaceUrl: true
      });
    });
  };
}
