import * as firebase from 'firebase';
import { LanguageCode } from '~core/model2/language';
import Timestamp = firebase.firestore.Timestamp;

export enum UserType {
  PATIENT = 'patient',
  DOCTOR = 'doctor'
}

export enum Gender {
  MALE = 'male',
  FEMALE = 'female'
}

export class User {
  uid?: string = null;
  email?: string = null;
  phone?: string = null;
  gender?: Gender = null;
  firstName?: string = null;
  lastName?: string = null;
  maidenName?: string = null;
  deviceInfo?: string = null;

  // TODO Change to Enum
  languagePreference?: LanguageCode = null;

  type?: UserType;
  lastSigninTime?: Timestamp | Date = new Date();
  signupTime?: Timestamp | Date = new Date();
  birthday?: Timestamp | Date = null;

  // TODO Should be fetched with hospitalId
  hospitalName?: string = null;
  hospitalID?: string = null;

  constructor(userData?: User & Record<string, any>) {
    if (userData) {
      Object.assign(this, userData);
      if ('HospitalID' in userData) {
        this.hospitalID = userData.HospitalID;
        delete (this as any).HospitalID;
      }
    }
  }

  isDoctor = (): boolean => this.type === UserType.DOCTOR;
  isPatient = (): boolean => this.type === UserType.PATIENT;
}

export class Doctor extends User {
  rpps?: string;

  constructor(userData?: User | Doctor) {
    super(userData);
  }
}

export class Patient extends User {
  constructor(userData?: User | Doctor) {
    super(userData);
  }
}

export interface PendingUser {
  firstName: string;
  lastName: string;
  email: string;
  birthday: Date;
  gender: Gender;
  phone: string;
  deadline: Date;
  identifiers?: Record<string, any>;
  hospitalId: string;
  lastAppointmentID: string;
}
