import { Injectable } from '@angular/core';
import { captureException, setUser, addBreadcrumb, Breadcrumb } from '@sentry/angular';
import { environment } from '~environments/environment';
import { User } from '~core/model2/domain/user';

@Injectable({
  providedIn: 'root'
})
export class SentryService {
  constructor() {
    this.setUser(null);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  captureException(exception: any, captureContext?: any): void {
    if (!environment.sentry.disabled) {
      captureException(exception, captureContext);
    }
    if (!environment.production) {
      console.log('SENTRY MANUAL REPORT:', exception, captureContext);
    }
  }

  setUser(user: Partial<User> | null): void {
    setUser(
      user && {
        id: user.uid,
        type: user.type
      }
    );
  }

  addBreadcrumb(breadcrumb: Breadcrumb): void {
    addBreadcrumb(breadcrumb);
  }
}
