import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '~core/services/auth.service';
import { AutoUnsubscribeComponent } from '~shared/auto-unsubscribe/auto-unsubscribe.component';
import { UserType } from '~core/model2/domain/user';
import { DialogService } from '~dialogs/service/dialog.service';

@Component({
  selector: 'user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'user'
})
export class UserComponent extends AutoUnsubscribeComponent {
  @Input() showAvatar: boolean;
  @Input() userType: UserType;
  @Input() email: string;

  userTypes = UserType;

  constructor(private router: Router, private authService: AuthService, private dialogService: DialogService) {
    super();
  }

  logout(): void {
    this.dialogService
      .showConfirmationDialog({
        message: 'AUTH.LogoutDialogMessage'
      })
      .then((confirmed) => {
        if (confirmed) {
          this.authService.logout();
        }
      });
  }
}
