import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSnackBarConfig } from '@angular/material/snack-bar/snack-bar-config';
import { TranslateService } from '@ngx-translate/core';

type SnackbarData = {
  messageId: string;
  actionId?: string;
  onAction?: () => void;
  config?: MatSnackBarConfig;
};

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  constructor(private matSnackBar: MatSnackBar, private translateService: TranslateService) {}

  public open(data: SnackbarData): ReturnType<InstanceType<typeof MatSnackBar>['open']> {
    const { messageId, actionId, onAction, config } = data;
    const message = this.translateService.instant(messageId);
    const action = actionId && this.translateService.instant(actionId);

    const snackbarRef = this.matSnackBar.open(message, action, {
      verticalPosition: 'bottom',
      horizontalPosition: 'end',
      panelClass: 'snackbar-panel',
      ...config
    });

    if (onAction) {
      snackbarRef.onAction().subscribe(() => {
        onAction();
      });
    }

    return snackbarRef;
  }
}
