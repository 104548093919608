import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import { DOCUMENT, Location } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { Intercom } from 'ng-intercom';
import { combineLatest } from 'rxjs';
import { AuthService } from '~core/services/auth.service';
import { AutoUnsubscribeComponent } from '~shared/auto-unsubscribe/auto-unsubscribe.component';
import { PrintService } from '~core/services/print.service';
import { LanguageService } from '~core/services/language.service';
import { LanguageCode } from '~core/model2/language';
import { User } from '~core/model2/domain/user';
import { ApplicationUpdateService } from '~core/services/application-update.service';
import { ConnectivityCheckerService } from '~core/services/connectivity-checker.service';
import { SnackbarService } from '~core/services/snackbar.service';
import { CUSTOM_DATE_FORMATS, CustomDateAdapter } from '~core/adapters/custom-date-adapter';
import { MaintenanceService } from '~core/services/maintenance.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS }
  ]
})
export class AppComponent extends AutoUnsubscribeComponent implements OnInit {
  private static readonly PUBLIC_PATHS = ['/login', '/signup'];

  ready = false;
  showMaintenance = false;

  private intercomInitialized = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private languageService: LanguageService,
    public printService: PrintService,
    private router: Router,
    private location: Location,
    private dateAdapter: DateAdapter<Date>,
    private updateService: ApplicationUpdateService,
    private connectivityChecker: ConnectivityCheckerService,
    private snackbarService: SnackbarService,
    private intercom: Intercom,
    private maintenance: MaintenanceService
  ) {
    super();
  }

  /* eslint-disable @typescript-eslint/naming-convention */
  ngOnInit(): void {
    combineLatest([
      this.maintenance.watchV2MigrationFields(),
      this.router.events.pipe(filter((e) => e instanceof NavigationEnd))
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([migration, ev]) => {
        this.showMaintenance = migration.blockAccess || migration.redirectToV2;
        if ((migration.blockAccess || migration.redirectToV2) && (ev as NavigationEnd).url !== '/maintenance') {
          // this.router.navigate(['/', 'maintenance']);
        } else if (!migration.blockAccess && !migration.redirectToV2 && (ev as NavigationEnd).url === '/maintenance') {
          this.router.navigate(['/']);
        }
      });

    this.languageService.currentLanguageCode.pipe(takeUntil(this.destroy$)).subscribe((lang) => {
      this.dateAdapter.setLocale(lang);
      if (this.intercomInitialized) {
        this.intercom.update({
          language_override: lang
        });
      }
    });

    this.updateService
      .availableUpdates()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.updateService.activateUpdate().then(() => document.location.reload());
      });

    this.connectivityChecker.online.pipe(takeUntil(this.destroy$)).subscribe((online) => {
      if (!online) {
        this.snackbarService.open({
          messageId: 'SNACKBAR.OFFLINE.MESSAGE',
          actionId: 'SNACKBAR.OFFLINE.ACTION'
        });
      }
    });
    this.languageService.updateCurrentLanguage(LanguageCode.FR);

    this.authService
      .getUser$()
      .pipe(
        filter((user: User) => !!user && !!user.uid),
        take(1),
        takeUntil(this.destroy$)
      )
      .subscribe((user: User) => {
        if (user.languagePreference) {
          this.languageService.updateCurrentLanguage(user.languagePreference);
        }
        if (!this.intercomInitialized) {
          setTimeout(() => {
            this.intercomInitialized = true;
          }, 1000);

          this.intercom.boot({
            api_base: 'https://api-iam.intercom.io',
            app_id: 'bjwshoq0',
            user_id: user.uid,
            email: user.email,
            name: `${user.firstName} ${user.lastName}`,
            language_override: user.languagePreference,
            type: user.type,
            phone: user.phone
          });
        }
      });

    this.authService
      .watchAuth()
      .pipe(
        tap(() => (this.ready = true)),
        tap(() => this.cdr.detectChanges()),
        takeUntil(this.destroy$)
      )
      .subscribe((isAuthenticated) => {
        if (!isAuthenticated) {
          this.intercom.shutdown();
          this.intercomInitialized = false;
        }
        if (isAuthenticated && this.currentlyInPublicRoute()) {
          const goTo = this.router.routerState.snapshot.root.queryParams.returnPath || '/';
          this.router.navigate([goTo]);
        } else if (!isAuthenticated && !this.currentlyInPublicRoute()) {
          this.router.navigate(
            ['/login'],
            !this.authService.loggedOut && {
              queryParams: {
                returnPath: this.location.path()
              }
            }
          );
        }
      });
  }

  private currentlyInPublicRoute(): boolean {
    return this.isPathPublic(this.location.path());
  }

  private isPathPublic(path: string) {
    return path && AppComponent.PUBLIC_PATHS.some((p) => path.startsWith(p));
  }
}
