import { NgModule } from '@angular/core';
import { MaintenanceAlertComponent } from './maintenance-alert/maintenance-alert.component';
import { SharedModule } from '~shared/shared.module';

@NgModule({
  declarations: [MaintenanceAlertComponent],
  exports: [MaintenanceAlertComponent],
  imports: [SharedModule]
})
export class MaintenanceAlertModule {}
