import { TreoNavigationItem } from '~treo/components/navigation';

export const doctorNavigation: TreoNavigationItem[] = [
  {
    id: 'applications_d',
    title: 'Découvrir Le Dossier Hôpital',
    translate: 'NAV.APPLICATIONS',
    type: 'group',
    children: [
      {
        id: 'applications_td',
        title: 'Questionnaire Pré-Anesthésique',
        translate: 'NAV.TRIAGEDASHBOARD',
        type: 'basic',
        icon: 'apps',
        link: '/doctor/dashboard'
      }
    ]
  },
  {
    id: 'applications',
    title: 'Découvrir le Dossier Patient',
    translate: 'NAV.APPLICATIONS',
    type: 'group',
    children: [
      {
        id: 'patient_portal',
        title: 'Portail Patient',
        translate: 'NAV.DASHBOARD',
        type: 'basic',
        icon: 'apps',
        link: '/patient/dashboard'
      },
      {
        id: 'applications_d',
        title: 'Profil & Aide',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        children: [
          {
            id: 'profile_d',
            title: 'Mon Profil',
            translate: 'NAV.DOCTOR.PROFILE',
            type: 'basic',
            icon: 'person',
            link: '/doctor/profile'
          },
          {
            id: 'faq_d',
            title: 'FAQ',
            translate: 'NAV.FAQ',
            type: 'basic',
            icon: 'help',
            link: '/under-construction'
          }
        ]
      }
    ]
  }
];

export const patientNavigation: TreoNavigationItem[] = [
  {
    id: 'applications',
    title: 'Dossier Patient',
    translate: 'NAV.APPLICATIONS',
    type: 'group',
    children: [
      {
        id: 'patient_portal',
        title: 'Préparer ma chirurgie',
        translate: 'NAV.DASHBOARD',
        type: 'basic',
        icon: 'apps',
        link: '/patient/dashboard'
      },
      {
        id: 'mesdoc',
        title: 'Mes documents',
        translate: 'NAV.MESDOC',
        type: 'collapsable',
        icon: 'receipt',
        children: [
          {
            id: 'resume',
            title: 'Ma Synthèse Medicale',
            translate: 'NAV.RESUME',
            type: 'basic',
            link: '/patient/details'
          },
          {
            id: 'medication',
            title: 'Mes Ordonnances',
            translate: 'NAV.MEDICATION',
            type: 'basic',
            link: '/patient/prescriptions'
          },
          {
            id: 'resume',
            title: 'Consentement Chirurgical',
            type: 'basic',
            link: '/under-construction'
          }
        ]
      },
      {
        id: 'profile',
        title: 'Mon Profil',
        translate: 'NAV.PROFILE',
        type: 'basic',
        icon: 'person',
        link: '/patient/profile'
      }
    ]
  }
];
