type GenericObject = Record<string | number | symbol, unknown>;

export function cleanseUndefinedValues(obj: GenericObject, ignorePredicate?: (v: any) => boolean): GenericObject {
  return Object.entries(obj)
    .filter(([, val]) => val !== undefined)
    .reduce((result, [key, val]) => {
      const shouldIgnore = !!ignorePredicate && ignorePredicate(val);
      if (!shouldIgnore && !!val && typeof val === 'object') {
        result[`${key}`] = Array.isArray(val)
          ? val.filter((v) => v !== undefined)
          : cleanseUndefinedValues(val as GenericObject, ignorePredicate);
        return result;
      }
      result[`${key}`] = val;
      return result;
    }, {});
}
