import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { GsResolverService } from '~core/services/gs-resolver.service';
import { SentryService } from '~core/services/sentry.service';

@Directive({
  selector: 'img[gsImageResolver]'
})
export class GsImageResolverDirective implements OnInit {
  private static readonly SPINNER_URL = 'assets/icons/photo-spinner.gif';

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('src') originalSrc?: string;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private gsResolverService: GsResolverService,
    private sentryService: SentryService
  ) {}

  ngOnInit(): void {
    this.showSpinner();
    this.gsResolverService
      .resolveUrl(this.originalSrc)
      .then((url) => this.setImgSrc(url))
      .catch((err) => {
        this.sentryService.captureException(err);
        this.setImgSrc(this.originalSrc);
      });
  }

  private setImgSrc(newSrc: string): void {
    this.renderer.setAttribute(this.elementRef.nativeElement, 'src', newSrc);
  }

  private showSpinner(): void {
    this.setImgSrc(GsImageResolverDirective.SPINNER_URL);
  }
}
