import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { PatientDocument } from '~core/model2/patient-document';
import { ImageViewService } from '~core/services/image-view.service';
import { AutoUnsubscribeComponent } from '~shared/auto-unsubscribe/auto-unsubscribe.component';
import { GsResolverService } from '~core/services/gs-resolver.service';
import { SentryService } from '~core/services/sentry.service';

enum Rotation {
  R_0,
  R_90,
  R_180,
  R_270
}

enum NaturalOrientation {
  HORIZONTAL,
  VERTICAL
}

enum ZoomLevel {
  Z_INITIAL,
  Z_125,
  Z_150,
  Z_175,
  Z_200,
  Z_MAX
}

@Component({
  selector: 'image-viewer-overlay',
  templateUrl: './image-viewer-overlay.component.html',
  styleUrls: ['./image-viewer-overlay.component.scss']
})
export class ImageViewerOverlayComponent extends AutoUnsubscribeComponent implements OnInit {
  imageDocument: PatientDocument;
  toolbarsVisible = false;

  private toolbarVisibilityTimeoutHandle: ReturnType<typeof setTimeout>;
  private rotation: Rotation = Rotation.R_0;
  private naturalOrientation = NaturalOrientation.VERTICAL;
  private zoomLevel: ZoomLevel = ZoomLevel.Z_INITIAL;
  private zoomLevelsCount: number;

  constructor(
    private imageViewService: ImageViewService,
    private changeDetector: ChangeDetectorRef,
    private gsResolverService: GsResolverService,
    @Inject(DOCUMENT) private domDocument: Document,
    private sentryService: SentryService
  ) {
    super();
  }

  ngOnInit(): void {
    this.zoomLevelsCount = Object.keys(ZoomLevel).length;
    this.imageViewService.displayImageChanged.pipe(takeUntil(this.destroy$)).subscribe((document: PatientDocument) => {
      this.setScrollbarVisibility(!document);
      this.imageDocument = document;
      if (document) {
        const image = new Image();
        image.onload = () => {
          this.naturalOrientation =
            image.width > image.height ? NaturalOrientation.HORIZONTAL : NaturalOrientation.VERTICAL;
          this.changeDetector.detectChanges();
        };
        image.src = document.path;
      } else {
        this.reset();
      }
    });
  }

  onClose(): void {
    this.imageViewService.displayImageChanged.next(null);
  }

  setToolbarsVisible(): void {
    this.toolbarsVisible = true;
    clearTimeout(this.toolbarVisibilityTimeoutHandle);
    this.toolbarVisibilityTimeoutHandle = setTimeout(() => {
      this.toolbarsVisible = false;
      this.changeDetector.detectChanges();
    }, 2000);
  }

  nextOrientation(): void {
    switch (this.rotation) {
      case Rotation.R_0:
        this.rotation = Rotation.R_90;
        break;
      case Rotation.R_90:
        this.rotation = Rotation.R_180;
        break;
      case Rotation.R_180:
        this.rotation = Rotation.R_270;
        break;
      case Rotation.R_270:
        this.rotation = Rotation.R_0;
        break;
    }

    this.changeDetector.detectChanges();
  }

  zoomIn(): void {
    this.zoomLevel = this.getNextZoomLevel();
  }

  zoomOut(): void {
    this.zoomLevel = this.getPrevZoomLevel();
  }

  getZoomStyle(): { transform: string } {
    let scale = 1;
    switch (this.zoomLevel) {
      case ZoomLevel.Z_INITIAL:
        scale = 1;
        break;
      case ZoomLevel.Z_125:
        scale = 1.25;
        break;
      case ZoomLevel.Z_150:
        scale = 1.5;
        break;
      case ZoomLevel.Z_175:
        scale = 1.75;
        break;
      case ZoomLevel.Z_200:
        scale = 2;
        break;
      case ZoomLevel.Z_MAX:
        scale = 2.2;
        break;
    }

    return {
      transform: `scale(${scale})`
    };
  }

  getRotationClass(): string {
    switch (this.rotation) {
      case Rotation.R_0:
        return 'rotate-0';
      case Rotation.R_90:
        return 'rotate-90';
      case Rotation.R_180:
        return 'rotate-180';
      case Rotation.R_270:
        return 'rotate-270';
    }
  }

  getDefaultOrientationClass(): string {
    switch (this.naturalOrientation) {
      case NaturalOrientation.HORIZONTAL:
        return 'default-horizontal';
      case NaturalOrientation.VERTICAL:
        return 'default-vertical';
    }
  }

  downloadDocument(): void {
    this.gsResolverService
      .resolveUrl(this.imageDocument.path)
      .then(fetch)
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = this.domDocument.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = this.imageDocument.name;
        this.domDocument.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        this.domDocument.body.removeChild(a);
      })
      .catch((err) => {
        this.sentryService.captureException(err);
      });
  }

  private setScrollbarVisibility(visible: boolean) {
    if (visible) {
      this.domDocument.body.classList.remove('scrollbar-hidden');
    } else {
      this.domDocument.body.classList.add('scrollbar-hidden');
    }
  }

  private reset(): void {
    this.rotation = Rotation.R_0;
    this.zoomLevel = ZoomLevel.Z_INITIAL;
    clearTimeout(this.toolbarVisibilityTimeoutHandle);
  }

  private getNextZoomLevel(): ZoomLevel {
    return this.isMaxZoom() ? ZoomLevel.Z_MAX : (this.zoomLevel + 1) % this.zoomLevelsCount;
  }

  private getPrevZoomLevel(): ZoomLevel {
    return this.isInitialZoom() ? ZoomLevel.Z_INITIAL : (this.zoomLevel - 1) % this.zoomLevelsCount;
  }

  private isInitialZoom: () => boolean = () => this.zoomLevel === ZoomLevel.Z_INITIAL;

  private isMaxZoom: () => boolean = () => this.zoomLevel === ZoomLevel.Z_MAX;
}
