<table class="print-root-container">
  <thead [ngStyle]="{ visibility: params.withHeader ? 'visible' : 'hidden' }">
    <tr>
      <td>
        <div class="header-space" [ngStyle]="{ height: params.headerHeight }">&nbsp;</div>
      </td>
    </tr>
  </thead>
  <tbody class="main-printable-area">
    <tr>
      <td>
        <div>
          <router-outlet></router-outlet>
        </div>
      </td>
    </tr>
  </tbody>
  <tfoot [ngStyle]="{ visibility: params.withFooter ? 'visible' : 'hidden' }">
    <tr>
      <td>
        <div class="footer-space">&nbsp;</div>
      </td>
    </tr>
  </tfoot>
</table>

<div *ngIf="params.withHeader" class="header" [ngStyle]="{ height: params.headerHeight }">
  <div *ngIf="!params.headerText && !params.headerLogo"></div>
  <div *ngIf="params.headerLogo" class="header-logo">
    <img src="/assets/images/logos/LogoSVG.svg" />
  </div>
  <div *ngIf="params.headerText" class="injected">{{ params.headerText }}</div>
</div>
<div *ngIf="params.withFooter" class="footer">
  <div *ngIf="!params.footerText"></div>
  <div *ngIf="params.footerText" class="injected">{{ params.footerText }}</div>
</div>
