import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import * as firebase from 'firebase';
import { ReauthenticateDialogData } from '~dialogs/model/dialog-data-types';

@Component({
  selector: 'reauth-dialog',
  templateUrl: './reauthenticate-dialog.component.html',
  styleUrls: ['./reauthenticate-dialog.component.scss']
})
export class ReauthenticateDialogComponent implements OnInit {
  authForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: ReauthenticateDialogData,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.authForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.required]
    });
    this.authForm.patchValue(this.data);
  }

  checkFormAndAuthenticate(): Promise<void> | void {
    const controls = this.authForm.controls;
    /** check form */
    if (this.authForm.invalid) {
      Object.keys(controls).forEach((controlName) => controls[`${controlName}`].markAsTouched());
    } else {
      return this.authenticate();
    }
  }

  private authenticate(): Promise<void> {
    const user = firebase.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(
      this.authForm.value.email,
      this.authForm.value.password
    );
    return user
      .reauthenticateWithCredential(credential)
      .then(() => {
        this.dialogRef.close(true);
      })
      .catch((error) => {
        let errorMessage = 'ERROR.UNEXPECTED';
        if (error?.code === 'auth/user-mismatch' || error?.code === 'auth/wrong-password') {
          errorMessage = 'ERROR.WRONG_CREDENTIALS';
        } else if (error?.code === 'auth/too-many-requests') {
          // @TODO maybe report this to sentry?
          errorMessage = 'ERROR.TOO_MANY_FAILED_LOGINS';
        }
        this.dialogRef.close(new Error(errorMessage));
      });
  }
}
