<div
  class="mx-auto flex flex-shrink-0 items-center justify-center h-12 w-12 rounded-full"
  [ngClass]="getBgColorClass()"
>
  <svg
    *ngIf="type === modalTypes.ERROR || type === modalTypes.WARNING"
    class="h-8 w-8"
    [class.text-red-600]="type === modalTypes.ERROR"
    [class.text-yellow-600]="type === modalTypes.WARNING"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
    />
  </svg>
  <svg
    *ngIf="type === modalTypes.SUCCESS"
    class="h-8 w-8 text-green-600"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
  </svg>

  <svg
    *ngIf="type === modalTypes.INFO"
    class="h-8 w-8 text-blue-600"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
  >
    <circle cx="12" cy="4" r="1" stroke-width="1" stroke-linejoin="round" fill="currentColor" />
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 10  V20" />
  </svg>
</div>
