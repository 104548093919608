import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})
export class GsResolverService {
  private urlCache: Map<string, string>;

  constructor(private fireStorage: AngularFireStorage) {
    this.urlCache = new Map<string, string>();
  }

  resolveUrl(gsPath: string): Promise<string> {
    if (!gsPath || !this.isGoogleStoragePath(gsPath)) {
      return Promise.resolve(gsPath);
    }

    if (this.isCached(gsPath)) {
      return Promise.resolve(this.getFromCache(gsPath));
    }

    return this.resolveImageUrl(gsPath).then((httpUrl: string) => {
      this.cacheUrl(gsPath, httpUrl);
      return Promise.resolve(httpUrl);
    });
  }

  private isGoogleStoragePath(url: string) {
    return url.startsWith('gs://');
  }

  private isCached(gsPath: string): boolean {
    return this.urlCache.has(gsPath);
  }

  private getFromCache(gsPath: string): string {
    return this.urlCache.get(gsPath);
  }

  private cacheUrl(key: string, url: string): void {
    this.urlCache.set(key, url);
  }

  private resolveImageUrl(filePath: string): Promise<string> {
    if (!filePath) {
      return Promise.reject(new Error());
    }
    return this.fireStorage.storage.refFromURL(filePath).getDownloadURL();
  }
}
