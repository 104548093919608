<div #recaptchaContainer></div>
<app-dialog-container>
  <div class="loadingSpinner" *ngIf="loading">
    <mat-spinner></mat-spinner>
  </div>
  <div>
    <div class="mt-3 text-center sm:mt-5">
      <dialog-title title="REAUTH.PHONE_HEADER"></dialog-title>
      <dialog-message
        message="{{ 'REAUTH.NEWNUMBER' | translate }}:  {{ data.phoneNumber }}"
      ></dialog-message>
    </div>
  </div>
  <div class="mt-6 flex flex-col justify-center items-center">
    <form #vf="ngForm" name="authForm" [formGroup]="verificationCodeForm" class="w-75">
      <mat-form-field appearance="fill" class="w-full">
        <input
          [disabled]="!recaptchaSolved"
          matInput
          placeholder="{{ 'REAUTH.VFCODE' | translate }}"
          formControlName="verificationCode"
        />
        <mat-icon matSuffix class="secondary-text">dialpad</mat-icon>
        <mat-error
          *ngIf="
            vf.form.controls['verificationCode'].dirty &&
            vf.form.controls['verificationCode'].errors?.required
          "
        >
          {{ 'REAUTH.VFCODE' | translate }}
        </mat-error>
      </mat-form-field>
    </form>
  </div>
  <div class="mt-6 flex justify-end">
    <button
      *ngIf="recaptchaSolved"
      mat-raised-button
      color="accent"
      class="submit-button"
      (click)="resendOtp()"
    >
      {{ 'REAUTH.RESEND' | translate }}
    </button>
    <div style="flex-grow: 1"></div>
    <button
      [disabled]="vf.form.controls['verificationCode'].invalid"
      (click)="verifyOtp()"
      class="inline-flex mr-2 justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
    >
      {{ 'REAUTH.OK' | translate }}
    </button>
    <button
      [mat-dialog-close]="false"
      class="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
    >
      {{ 'PROFILE.CANCEL' | translate }}
    </button>
  </div>
</app-dialog-container>
