import { PreloadingStrategy, Route } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { User, UserType } from '../model2/domain/user';

export enum UserBasedPreloadingStrategy {
  PATIENT_ONLY,
  DOCTOR_ONLY,
  ALWAYS,
  NEVER
}

export class UserBasedPreloaderRouteData {
  readonly preload: boolean;
  readonly preloadForType?: UserType | UserType[];

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  static forStrategy(strategy: UserBasedPreloadingStrategy): UserBasedPreloaderRouteData {
    switch (strategy) {
      case UserBasedPreloadingStrategy.PATIENT_ONLY:
        return {
          preload: true,
          preloadForType: UserType.PATIENT
        };
      case UserBasedPreloadingStrategy.DOCTOR_ONLY:
        return {
          preload: true,
          preloadForType: UserType.DOCTOR
        };
      case UserBasedPreloadingStrategy.ALWAYS:
        return {
          preload: true
        };
      case UserBasedPreloadingStrategy.NEVER:
        return {
          preload: false
        };
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class UserBasedPreloader implements PreloadingStrategy {
  constructor(private authService: AuthService) {}

  preload(route: Route, load: () => Observable<any>): Observable<any> {
    const preloadData = route.data && (route.data['preloadingData'] as UserBasedPreloaderRouteData);
    const doNotPreload = of(null);

    if (preloadData) {
      const { preload, preloadForType } = preloadData;

      if (!preload) {
        return doNotPreload;
      }

      if (preload && !preloadForType) {
        return load();
      }

      return this.authService.getUser$().pipe(
        switchMap((user: User) => {
          if (user && user.type === preloadForType) {
            return load();
          }
          return doNotPreload;
        })
      );
    }

    return doNotPreload;
  }
}
