import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AngularFireAuthGuard, AngularFireAuthGuardModule, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { PrintLayoutComponent } from 'app//print/print-layout/print-layout.component';
import { PrintModule } from 'app//print/print.module';
import { UnderConstructionModule } from 'app//under-construction/under-construction.module';
import { Patient2Module } from 'app//patient2/patient2.module';
import { Doctor2Module } from 'app//doctor2/doctor2.module';
import { SignUpModule } from 'app//auth2/signup/signup.module';
import { LoginModule } from 'app//auth2/login/login.module';
import { PageNotFoundModule } from 'app//error-pages/page-not-found/page-not-found.module';
import { Observable, UnaryFunction } from 'rxjs';
import { User } from 'firebase';
import { OfflineModule } from 'app//error-pages/offline/offline.module';
import { MaintenanceModule } from 'app//maintenance/maintenance.module';
import {
  UserBasedPreloader,
  UserBasedPreloaderRouteData,
  UserBasedPreloadingStrategy
} from '~core/preloading/user-based-preloader';
import { IsDoctorGuard } from '~core/guards/is-doctor.guard';
import { RootRedirectionGuard } from '~core/guards/root-redirection.guard';
import { NoAuthGuard } from '~core/guards/no-auth.guard';
import { LayoutComponent } from '~layout/layout.component';
import { IsOnlineGuard } from '~core/guards/is-online.guard';

const redirectUnauthorizedToLogin = (): UnaryFunction<Observable<User | null>, Observable<true | any[]>> =>
  redirectUnauthorizedTo(['/login']);

const routerConfig: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  preloadingStrategy: UserBasedPreloader
};

export const appRoutes: Routes = [
  {
    path: 'maintenance',
    pathMatch: 'full',
    loadChildren: (): Promise<MaintenanceModule> =>
      import('./maintenance/maintenance.module').then((m) => m.MaintenanceModule),
    component: LayoutComponent,
    data: {
      layout: 'empty'
    }
  },
  {
    path: '',
    pathMatch: 'full',
    canActivate: [RootRedirectionGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty'
    }
  },
  {
    path: 'login',
    loadChildren: (): Promise<LoginModule> => import('./auth2/login/login.module').then((m) => m.LoginModule),
    component: LayoutComponent,
    canActivate: [NoAuthGuard],
    data: {
      layout: 'empty',
      preloadingData: UserBasedPreloaderRouteData.forStrategy(UserBasedPreloadingStrategy.NEVER)
    }
  },
  {
    path: 'signup',
    loadChildren: (): Promise<SignUpModule> => import('./auth2/signup/signup.module').then((m) => m.SignUpModule),
    component: LayoutComponent,
    canActivate: [NoAuthGuard],
    data: {
      layout: 'empty',
      preloadingData: UserBasedPreloaderRouteData.forStrategy(UserBasedPreloadingStrategy.NEVER)
    }
  },
  {
    path: 'doctor',
    component: LayoutComponent,
    loadChildren: (): Promise<Doctor2Module> => import('./doctor2/doctor2.module').then((m) => m.Doctor2Module),
    canActivate: [AngularFireAuthGuard, IsDoctorGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'patient',
    component: LayoutComponent,
    loadChildren: (): Promise<Patient2Module> => import('./patient2/patient2.module').then((m) => m.Patient2Module),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'under-construction',
    component: LayoutComponent,
    loadChildren: (): Promise<UnderConstructionModule> =>
      import('./under-construction/under-construction.module').then((m) => m.UnderConstructionModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'print',
    outlet: 'print',
    component: PrintLayoutComponent,
    loadChildren: (): Promise<PrintModule> => import('./print/print.module').then((m) => m.PrintModule),
    canActivate: [IsOnlineGuard, AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      preloadingData: UserBasedPreloaderRouteData.forStrategy(UserBasedPreloadingStrategy.NEVER)
    }
  },
  {
    path: 'offline',
    component: LayoutComponent,
    loadChildren: (): Promise<OfflineModule> =>
      import('./error-pages/offline/offline.module').then((m) => m.OfflineModule),
    data: {
      layout: 'empty',
      preloadingData: UserBasedPreloaderRouteData.forStrategy(UserBasedPreloadingStrategy.NEVER)
    }
  },
  {
    path: '**',
    component: LayoutComponent,
    loadChildren: (): Promise<PageNotFoundModule> =>
      import('./error-pages/page-not-found/page-not-found.module').then((m) => m.PageNotFoundModule),
    data: {
      layout: 'empty',
      preloadingData: UserBasedPreloaderRouteData.forStrategy(UserBasedPreloadingStrategy.NEVER)
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, routerConfig), AngularFireAuthGuardModule],
  exports: [RouterModule]
})
export class AppRoutingModule {}
