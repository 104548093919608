import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import * as firebase from 'firebase';
import { HttpClient } from '@angular/common/http';
import { format } from 'date-fns';
import { Rpps } from '~core/model2/domain/rpps';
import { Gender, PendingUser, User } from '~core/model2/domain/user';
import { FirestoreCollections } from '~core/services/firestore-collections';
import { environment } from '~environments/environment';
import UserCredential = firebase.auth.UserCredential;
import RecaptchaVerifier = firebase.auth.RecaptchaVerifier;
import ConfirmationResult = firebase.auth.ConfirmationResult;
import AuthCredential = firebase.auth.AuthCredential;
import { parseDate } from '~core/utils/date.utils';
import { cleanseUndefinedValues } from '~core/utils/fp.utils';
import Timestamp = firebase.firestore.Timestamp;
import { SentryService } from '~core/services/sentry.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly user: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  private initialized = false;
  /** Used to determine if user has been redirected to login page due to explicitly logged out or redirected from a non-public route*/
  private _loggedOut = false;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private http: HttpClient,
    sentryService: SentryService
  ) {
    this.user.subscribe((user) => {
      sentryService.setUser(user);
    });
  }

  get loggedOut(): boolean {
    return this._loggedOut;
  }

  findPendingUser(uid: string): Promise<PendingUser> {
    return this.http
      .get(`${environment.connectorSignupAPI}?uid=${uid}`)
      .pipe(
        map(
          (response: any) =>
            ({
              ...response,
              firstName: response.firstName,
              lastName: response.lastName,
              email: response.email,
              birthday: new Date(response.birthday),
              gender: Gender[response.gender?.toUpperCase()],
              phone: response.phone,
              deadline:
                typeof response.deadline === 'string' ? new Date(response.deadline) : response.deadline.toDate(),
              hospitalId: response.HospitalID || ''
            } as PendingUser)
        )
      )
      .toPromise();
  }

  emailExists(email: string): Promise<boolean> {
    if (!email) {
      return Promise.reject(new Error('ERROR.NO_EMAIL_PROVIDED'));
    }
    return this.afAuth.auth.fetchSignInMethodsForEmail(email).then((methods: string[]) => methods.length > 0);
  }

  /**
   * get current login user info
   */
  getUser$(): Observable<User> {
    return this.user.pipe(filter(() => this.initialized));
  }

  setUser(user: User | null): void {
    if (!this.initialized) {
      this.initialized = true;
    }
    if (user) {
      localStorage.setItem('userType', user.type);
    }
    const newUser: User | null = user && new User(user);
    this.user.next(newUser);
  }

  getUserValue(): User | null {
    return this.initialized ? this.user.getValue() : null;
  }

  /**
   * check the current user return user ID or redirect login page
   */
  watchAuth(): Observable<boolean> {
    return this.afAuth.authState.pipe(
      switchMap((registeredUser: firebase.User) => {
        if (!registeredUser?.uid) {
          return of(null);
        }

        return this.getCurrentUserData$(registeredUser.uid).pipe(
          take(1),
          catchError(() => of(null))
        );
      }),
      tap((user: User | null) => {
        this.setUser(user);
      }),
      map((user: User | null) => !!user)
    );
  }

  /**
   * login with email and password
   *
   * @param email
   * @param password
   */
  login(email: string, password: string): Promise<string> {
    return this.afAuth.auth
      .signInWithEmailAndPassword(email, password)
      .then((user: UserCredential) => {
        this._loggedOut = false;
        return this.updateLastLoginTime(user);
      })
      .then(() => this.getCurrentUserToken())
      .then((token) => this.setCustomClaims(token))
      .then(() => this.afAuth.auth.currentUser.getIdToken(true));
  }

  getCurrentUserToken(): Promise<string> {
    return this.afAuth.auth.currentUser.getIdToken();
  }

  setCustomClaims(token: string): Promise<any> {
    return this.http.post(environment.setCustomClaimsEndpoint, { token: token }).toPromise();
  }

  loginWithPhoneNumber(phoneNumber: string, recaptchaVerifier: RecaptchaVerifier): Promise<ConfirmationResult> {
    return this.afAuth.auth.signInWithPhoneNumber(phoneNumber, recaptchaVerifier);
  }

  signUp(user: User, password: string, extraFields?: Record<string, any>): Promise<void> {
    if (!user?.email || !password) {
      return Promise.reject(new Error('Unexpected error'));
    }

    let promise: Promise<UserCredential>;
    if (!!this.afAuth.auth.currentUser) {
      const creds = this.createEmailCredentials(user.email, password);
      promise = this.linkCurrentUserToCredential(creds);
    } else {
      promise = this.afAuth.auth.createUserWithEmailAndPassword(user.email, password);
    }

    return promise
      .then((userCredential: UserCredential) => {
        user.uid = userCredential.user.uid;
        return this.createUserInDb$(user, extraFields);
      })
      .then(() => this.setUser(user)) // immediately set user after signup for proper redirect
      .then(() => {
        this.login(user.email, password);
      });
  }

  /**
   * logout using firebase logout function
   */
  logout(): Promise<void> {
    return this.afAuth.auth.signOut().then(() => {
      this._loggedOut = true;
    });
  }

  updateUserPassword(newPass: string): Promise<void> {
    return this.afAuth.auth.currentUser.updatePassword(newPass);
  }

  /**
   * get current user by Id from DB
   *
   * @param uid
   */
  getCurrentUserData$(uid: string): Observable<User> {
    return this.afs
      .collection(FirestoreCollections.USERS)
      .doc<User>(uid)
      .valueChanges()
      .pipe(
        map((u) => ({
          ...u,
          birthday: parseDate(u.birthday),
          lastSigninTime: parseDate(u.lastSigninTime),
          signupTime: parseDate(u.signupTime)
        }))
      );
  }

  resetPassword(email: string): Promise<void> {
    if (!email) {
      return Promise.reject(new Error('Not a valid email'));
    }
    return this.afAuth.auth.sendPasswordResetEmail(email);
  }

  updateLastLoginTime(userCredential: UserCredential): Promise<void> {
    if (!userCredential) {
      return Promise.reject(new Error('ERROR.NO_USER'));
    }

    const userId = userCredential.user.uid;
    if (!userId) {
      return Promise.reject(new Error('ERROR.UNEXPECTED'));
    }

    return this.afs.collection(FirestoreCollections.USERS).doc<User>(userId).update({
      lastSigninTime: new Date()
    });
  }

  /**
   * create new RPPS in DB
   *
   * @param rpps
   */
  createRpps(rpps: Rpps): Promise<void> {
    return this.afs.collection(FirestoreCollections.RPPS_ID).doc(rpps.rppsID).set(rpps);
  }

  /**
   * get Rpps information from DB by rpps ID
   *
   * @param rpps
   */
  getRppsInfo(rpps: string): Promise<null | Rpps> {
    return this.afs
      .collection(FirestoreCollections.RPPS_ID)
      .doc<Rpps>(rpps)
      .get()
      .toPromise()
      .then((doc) => {
        if (!doc.exists) {
          return null;
        } else {
          return doc.data() as Rpps;
        }
      });
  }

  deleteUserAccount(user: User, password: string): Promise<void> {
    return this.login(user.email, password)
      .then(() => this.deleteFromTriageDashboard(user))
      .then(() => this.deleteFromUsersCollection(user))
      .then(() => firebase.auth().currentUser.delete())
      .then(
        () =>
          new Promise((resolve) => {
            localStorage.clear();
            resolve();
          })
      );
  }

  private createEmailCredentials(email: string, password: string): AuthCredential {
    return firebase.auth.EmailAuthProvider.credential(email, password);
  }

  private linkCurrentUserToCredential(credential: AuthCredential): Promise<UserCredential> {
    return this.afAuth.auth.currentUser.linkWithCredential(credential);
  }

  private createUserInDb$(user: User, extraFields?: Record<string, any>): Promise<void> {
    if (!user?.uid) {
      return Promise.reject(new Error('User not created'));
    }

    const dbUser = {
      ...user,
      birthday: this.stringifyDate(user.birthday),
      ...extraFields,
      // @TODO this sucks. Create a backend script to migrate HospitalID to hospitalId in the db
      // eslint-disable-next-line @typescript-eslint/naming-convention
      HospitalID: user.hospitalID
    };
    delete dbUser.hospitalID;
    const isDate = (v: any) => v instanceof Date || v instanceof Timestamp;
    return this.afs
      .collection(FirestoreCollections.USERS)
      .doc(user.uid)
      .set(cleanseUndefinedValues(dbUser, isDate), { merge: true });
  }

  private deleteFromUsersCollection(user: User): Promise<void> {
    return this.afs.collection(FirestoreCollections.USERS).doc(user.uid).delete();
  }

  private deleteFromTriageDashboard(user: User): Promise<void> {
    return this.afs
      .collection(FirestoreCollections.TRIAGE_DASHBOARD)
      .doc(user.hospitalID)
      .collection(FirestoreCollections.PATIENT_SUB_COLLECTION)
      .doc(user.uid)
      .delete();
  }

  private stringifyDate(date?: firebase.firestore.Timestamp | Date): string {
    if (!date) {
      return '';
    }

    const d = date instanceof Date ? date : date.toDate();
    return format(d, 'yyyy-MM-dd');
  }
}
