import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dialog-message',
  templateUrl: './dialog-message.component.html',
  styleUrls: ['./dialog-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogMessageComponent implements OnInit {
  private static readonly LINK_PLACEHOLDER = /##link_here##/;

  @Input() message: string;
  @Input() link: string;

  textualParts: string[];

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    if (this.message && this.link) {
      this.textualParts = this.translateService
        .instant(this.message || '')
        .split(DialogMessageComponent.LINK_PLACEHOLDER);
    }
  }
}
