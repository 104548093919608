<app-dialog-container>
  <div>
    <div class="mt-3 text-center sm:mt-5">
      <dialog-title title="PROFILE.UPDATE_PASS_MODAL.TITLE"></dialog-title>
    </div>
  </div>
  <div class="mt-6 flex justify-center items-center">
    <form name="authForm" [formGroup]="passForm">
      <mat-form-field appearance="fill" class="w-full">
        <input
          matInput
          placeholder="{{ 'PROFILE.UPDATE_PASS_MODAL.NEW_PASS_LABEL' | translate }}"
          [type]="showPassword ? 'text' : 'password'"
          formControlName="newPassword"
        >
        <button mat-icon-button matSuffix (click)="showPassword = !showPassword">
          <mat-icon class="secondary-text">{{showPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        <mat-error>
          {{ 'REAUTH.PASSWORD' | translate }} {{ 'REAUTH.INVALID' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill" class="w-full">
        <input
          matInput
          placeholder="{{ 'PROFILE.UPDATE_PASS_MODAL.PASS_CONFIRM_LABEL' | translate }}"
          [type]="showPassword ? 'text' : 'password'"
          formControlName="confirmPassword"
        >
        <button mat-icon-button matSuffix (click)="showPassword = !showPassword">
          <mat-icon class="secondary-text">{{showPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        <mat-error>
          {{ 'PROFILE.UPDATE_PASS_MODAL.PASS_CONFIRM_NOT_MATCH' | translate }}
        </mat-error>
      </mat-form-field>
    </form>
  </div>
  <div class="mt-6 flex justify-end">
    <button
          [disabled]="passForm.invalid"
          [style]="{opacity: passForm.invalid ? 0.5 : 1, pointerEvents: passForm.invalid ? 'none' : 'all'}"
          [mat-dialog-close]="passForm.controls.newPassword?.value"
          class="inline-flex mr-2 justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
    >
      {{ 'REAUTH.OK' | translate}}
    </button>
    <button [mat-dialog-close]="false" class="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm">
      {{ 'PROFILE.CANCEL' | translate }}
    </button>
  </div>
</app-dialog-container>
