import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { environment } from '~environments/environment';

@Component({
  selector: 'app-password-update-dialog',
  templateUrl: './password-update-dialog.component.html',
  styleUrls: ['./password-update-dialog.component.scss']
})
export class PasswordUpdateDialogComponent implements OnInit {
  passForm: FormGroup;
  showPassword: boolean;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    const passValidator = Validators.compose([
      Validators.required,
      Validators.min(environment.minPasswordLength),
      Validators.max(environment.maxPasswordLength),
      Validators.pattern(environment.passwordPattern)
    ]);

    const passMatchValidator = (control: AbstractControl): ValidationErrors | null => {
      if (!control || !this.passForm?.controls?.newPassword?.value) {
        return null;
      }
      if (control.value === this.passForm.controls.newPassword.value) {
        return null;
      }

      return { confirmPassword: true };
    };

    const confirmValidator = Validators.compose([Validators.required, passMatchValidator]);

    this.passForm = this.formBuilder.group({
      newPassword: ['', passValidator],
      confirmPassword: ['', confirmValidator]
    });
  }
}
