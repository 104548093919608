import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ConnectivityCheckerService } from '../services/connectivity-checker.service';

@Injectable({
  providedIn: 'root'
})
export class IsOnlineGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private connectivityCheckerService: ConnectivityCheckerService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean> | boolean {
    return this.check(state);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.check(state);
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean> | boolean {
    return this.check();
  }

  private check(state?: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.connectivityCheckerService.online.pipe(
      take(1),
      map(
        (isOnline: boolean) =>
          isOnline ||
          this.router.createUrlTree(['/offline'], {
            queryParams: {
              returnPath: state?.url || window.location.href
            }
          })
      )
    );
  }
}
