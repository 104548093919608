/* eslint @typescript-eslint/no-var-requires: "off" */

import envVars from './env-vars';

export const environment = {
  appVersion: require('../../package.json').version + '-dev',
  production: false,
  disableTwoFactorAuthentication: false,
  authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
  apiEndpoint: 'https://europe-west1-leafy-mountain-248914.cloudfunctions.net',
  snowMedAPi: 'http://35.242.164.138:8080/MAIN',
  setCustomClaimsEndpoint: 'https://europe-west1-leafy-mountain-248914.cloudfunctions.net/auth',
  ocrBloodWorkAPI: 'https://europe-west1-leafy-mountain-248914.cloudfunctions.net/ocr_bloodwork',
  connectorSignupAPI: 'https://europe-west1-leafy-mountain-248914.cloudfunctions.net/foch_connector_display_signup',
  firebase: {
    apiKey: 'AIzaSyBwGikFLaqEmdRqYYixCxVLyFinXVTP-Cc',
    authDomain: 'leafy-mountain-248914.firebaseapp.com',
    databaseURL: 'https://leafy-mountain-248914.firebaseio.com',
    projectId: 'leafy-mountain-248914',
    storageBucket: 'leafy-mountain-248914-storage-bucket',
    messagingSenderId: '848675943266',
    appId: '1:848675943266:web:facf2d48e559137b'
  },
  delayedTime: 10,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  IntercomID: 'bjwshoq0',
  enableCypressTesting: true,
  passwordPattern: envVars.passwordPattern,
  minPasswordLength: envVars.minPasswordLength,
  maxPasswordLength: envVars.maxPasswordLength,
  sentry: {
    disabled: false,
    dsn: 'https://c5f4adc6409e44e3b4675d55461de317@o844316.ingest.sentry.io/5825116',
    tracingOrigins: ['https://dev.ianesthesie.com/'],
    sampleRate: 0.5
  },
  webPush: {
    publicKey: ''
  }
};
