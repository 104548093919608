export enum FirestoreCollections {
  GENERATED_PRESCRIPTIONS = 'TriagePrescGen',
  HOSPITALS = 'Hospitals',
  PATIENT_SUB_COLLECTION = 'Patients',
  RPPS_ID = 'rppsID',
  SPECIAL_LINKS = 'SpecialLinks',
  TRIAGE_DASHBOARD = 'TriageDashboard5',
  TRIAGE_DASHBOARD_STATUS = 'TriageDashboardStatus',
  TRIAGE_PATIENTS = 'TriagePatients',
  TRIAGE_PRESCRIPTION_OCR = 'TriagePrescription',
  TRIAGE_QUESTIONNAIRE = 'TriageQuestionnaire',
  TRIAGE_QUESTIONNAIRE_COMPLETE = 'TriageQuestionnaireComplete',
  TRIAGE_QUESTIONNAIRE_RESUME = 'TriageQuestionnaireResume',
  USERS = 'users',
  ANEMIA_BLOODWORK = 'AnemiaBloodwork',
  MAINTENANCE = 'Maintenance'
}
