<app-dialog-container>
  <div>
    <div class="mt-3 text-center sm:mt-5">
      <dialog-title title="REAUTH.REAUTH"></dialog-title>
      <dialog-message message="REAUTH.HEADER"></dialog-message>
    </div>
  </div>
  <div class="mt-6 flex justify-center items-center">
    <form name="authForm" [formGroup]="authForm">
      <mat-form-field appearance="fill" class="w-full">
        <input matInput placeholder="Email" formControlName="email" />
        <mat-icon matSuffix class="secondary-text">mail</mat-icon>
        <mat-error *ngIf="authForm.get('email').hasError('required')">Email</mat-error>
        <mat-error
          *ngIf="!authForm.get('email').hasError('required') && authForm.get('email').hasError('email')"
        >
          Email {{ 'REAUTH.INVALID' | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="w-full">
        <input
          matInput
          placeholder="{{ 'REAUTH.PASSWORD' | translate }}"
          type="password"
          formControlName="password"
        />
        <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
        <mat-error>{{ 'REAUTH.PASSWORD' | translate }} {{ 'REAUTH.INVALID' | translate }}</mat-error>
      </mat-form-field>
    </form>
  </div>
  <div class="mt-6 flex justify-end">
    <button
      [disabled]="authForm.invalid"
      (click)="checkFormAndAuthenticate()"
      class="inline-flex mr-2 justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
    >
      {{ 'REAUTH.OK' | translate }}
    </button>
    <button
      [mat-dialog-close]="false"
      class="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
    >
      {{ 'PROFILE.CANCEL' | translate }}
    </button>
  </div>
</app-dialog-container>
