import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import firebase from 'firebase';
import { enUS, es, fr } from 'date-fns/locale';
import { format, Locale as DateFnsLocale, parse, isValid } from 'date-fns';
import { TranslateService } from '@ngx-translate/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { DateAdapter } from '@angular/material/core';
import { Language, LanguageCode } from '~core/model2/language';
import Timestamp = firebase.firestore.Timestamp;
import { DateType } from '~core/model2/date-type';
import { FirestoreCollections } from '~core/services/firestore-collections';
import { SentryService } from '~core/services/sentry.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private static readonly LOCAL_STORAGE_LANG_KEY = 'lang';

  readonly currentLanguageCode: BehaviorSubject<LanguageCode>;

  private localeMap: Map<LanguageCode, DateFnsLocale>;

  constructor(
    private translateService: TranslateService,
    private afs: AngularFirestore,
    private dateAdapter: DateAdapter<any>,
    private sentryService: SentryService
  ) {
    this.currentLanguageCode = new BehaviorSubject(LanguageService.getLangCodeFromLocalStorage() || LanguageCode.FR);
    this.localeMap = new Map<LanguageCode, DateFnsLocale>([
      [LanguageCode.FR, fr],
      [LanguageCode.EN, enUS],
      [LanguageCode.ES, es]
    ]);

    this.currentLanguageCode.subscribe(LanguageService.saveToLocalStorage);
  }

  static getLangCodeFromLocalStorage(): LanguageCode | null {
    return localStorage.getItem(LanguageService.LOCAL_STORAGE_LANG_KEY) as LanguageCode | null;
  }

  private static saveToLocalStorage(lang: LanguageCode): void {
    localStorage.setItem(LanguageService.LOCAL_STORAGE_LANG_KEY, lang);
  }

  updateCurrentLanguage(lang: Language | LanguageCode): void {
    const newLangCode = lang.hasOwnProperty('code') ? (lang as Language).code : (lang as LanguageCode);
    this.translateService.use(newLangCode);
    this.dateAdapter.setLocale(newLangCode);
    this.currentLanguageCode.next(newLangCode);
  }

  parseDate(dateTime: string): Date | null {
    if (dateTime.length !== 10) {
      return null;
    }
    const parsedDate = parse(dateTime, 'P', new Date(), { locale: this.getCurrentDateFnsLocale() });
    if (!isValid(parsedDate)) {
      return null;
    }
    return parsedDate;
  }

  formatLocaleDate(dateTime: DateType, dateFormat = 'P'): string {
    if (!dateTime) {
      return '';
    }

    if (dateTime instanceof Timestamp) {
      dateTime = dateTime.toMillis();
    }
    if (dateTime instanceof Number) {
      dateTime = parse(String(dateTime), 'T', new Date());
    } else if (dateTime instanceof String) {
      dateTime = parse(`${dateTime}`, dateFormat, new Date(), {
        locale: this.getCurrentDateFnsLocale()
      });
    }

    return format(dateTime as Date | number, 'P', {
      locale: this.getCurrentDateFnsLocale()
    });
  }

  saveUserLanguagePreference(userId: string, lang: Language): void {
    if (userId && lang) {
      this.afs
        .collection(FirestoreCollections.USERS)
        .doc(userId)
        .update({ languagePreference: lang.code })
        .catch((err) => {
          // @TODO display error dialog
          this.sentryService.captureException(err);
        });
    }
  }

  findLanguageCodeByValue(langString: string): LanguageCode {
    if (!langString) {
      throw new Error(`Unknown lang: ${langString}`);
    }

    const langCode = Object.values(LanguageCode).find((l) => l === langString);
    if (!langCode) {
      throw new Error(`Unknown lang: ${langString}`);
    }

    return langCode;
  }

  private getCurrentDateFnsLocale(): DateFnsLocale {
    // eslint-disable-next-line rxjs/no-subject-value
    return this.localeMap.get(this.currentLanguageCode.getValue());
  }
}
