<!-- Button -->
<button class="icon-button" mat-icon-button [matMenuTriggerFor]="userActions">
  <span class="avatar">
    <mat-icon [svgIcon]="'account_circle'"></mat-icon>
    <span class="status"></span>
  </span>
</button>

<mat-menu class="user-actions-menu" [xPosition]="'before'" #userActions="matMenu">
  <button mat-menu-item>
    <span class="user-info">
      <span>{{ 'NAV.SIGNEDINNAS' | translate }}</span>
      <span class="email">{{ email }}</span>
    </span>
  </button>
  <mat-divider class="my-2"></mat-divider>
  <button mat-menu-item [routerLink]="[userType === userTypes.DOCTOR ? '/doctor' : '/patient', 'profile']">
    <mat-icon [svgIcon]="'account_circle'"></mat-icon>
    <span>{{ 'NAV.PROFILE' | translate }}</span>
  </button>
  <mat-divider class="my-2"></mat-divider>
  <button mat-menu-item (click)="logout()">
    <mat-icon [svgIcon]="'power_settings_new'"></mat-icon>
    <span>{{ 'NAV.SIGNOUT' | translate }}</span>
  </button>
</mat-menu>
