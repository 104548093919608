import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { AppModule } from 'app//app.module';
import { environment } from '~environments/environment';

if (environment.production) {
  /* window.console.log=function(){};
	window.console.warn=function(){};
	window.console.error=function(){}; */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  window.console.time = function () {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  window.console.timeEnd = function () {};
  enableProdMode();
}

Sentry.init({
  dsn: environment.sentry.dsn,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: environment.sentry.tracingOrigins,
      routingInstrumentation: Sentry.routingInstrumentation
    })
  ],
  tracesSampleRate: environment.sentry.sampleRate,
  release: `ianesthesie@${environment.appVersion}`,
  enabled: !environment.sentry.disabled,
  beforeSend: filterSentryErrors
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

const ignoreMessages = [
  'chunkloaderror',
  'loading chunk',
  'loading icon set url',
  'a mutation operation was attempted',
  "failed to execute 'transaction'",
  'failed to get document because the client is offline',
  "failed to execute 'createobjectstore' on 'idbdatabase'",
  'the requested path contains undefined segment',
  'timeouterror: transaction timed out',
  'the transaction was aborted',
  'there is no user record corresponding to this identifier',
  'http failure response for /assets/i18n',
  'installations/app-offline',
  'the database connection is closing',
  'we have blocked all requests from this device due to unusual activity',
  'network error'
];

function filterSentryErrors(
  event: Sentry.Event,
  hint: Sentry.EventHint
): PromiseLike<Sentry.Event | null> | Sentry.Event | null {
  const error = hint.originalException;
  if (typeof error === 'string') {
    return event;
  }
  const message = error?.message?.toLowerCase();
  if (message) {
    if (message === 'network error' || ignoreMessages.some((m) => message.includes(m))) {
      return null;
    }
  }

  return event;
}
