import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MaintenanceAlertModule } from 'app//maintenance-alert/maintenance-alert.module';
import { SharedModule } from '~shared/shared.module';
import { EmptyLayoutComponent } from '~layout/layouts/empty/empty.component';

@NgModule({
  declarations: [EmptyLayoutComponent],
  imports: [RouterModule, SharedModule, MaintenanceAlertModule],
  exports: [EmptyLayoutComponent]
})
export class EmptyLayoutModule {}
