<div *ngIf="!!imageDocument" (mousemove)="setToolbarsVisible()">
  <div class="image-overlay">
    <div class="top-toolbar" [class.visible]="toolbarsVisible">
      <h2>{{ imageDocument.name | translate }}</h2>
      <div class="buttons">
        <button (click)="downloadDocument()"><mat-icon>cloud_download</mat-icon></button>
        <button (click)="nextOrientation()"><mat-icon>refresh</mat-icon></button>
        <button (click)="onClose()"><mat-icon>close</mat-icon></button>
      </div>
    </div>
    <div class="image-container" [ngStyle]="getZoomStyle()">
      <img gsImageResolver [ngClass]="[getRotationClass(), getDefaultOrientationClass()]" [src]="imageDocument.path" />
    </div>
    <div class="bottom-toolbar" [class.visible]="toolbarsVisible">
      <button (click)="zoomIn()"><mat-icon>zoom_in</mat-icon></button>
      <button (click)="zoomOut()"><mat-icon>zoom_out</mat-icon></button>
    </div>
  </div>
</div>
