import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFirePerformanceModule } from '@angular/fire/performance';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import fr from '@angular/common/locales/fr';
import { MatNativeDateModule } from '@angular/material/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { IntercomModule } from 'ng-intercom';
import { MaintenanceModule } from 'app//maintenance/maintenance.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DialogsModule } from '~dialogs/dialogs.module';
import { CoreModule } from '~core/core.module';
import { environment } from '~environments/environment';
import { LayoutModule } from '~layout/layout.module';
import { appConfig } from '~core/config/app.config';
import { TreoModule } from '~treo';
import { TreoConfigModule } from '~treo/services/config';

registerLocaleData(fr);

// required for AOT compilation
export function httpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFirePerformanceModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    AngularFireStorageModule,
    // Treo
    TreoModule,
    TreoConfigModule.forRoot(appConfig),
    // Core
    CoreModule,
    // Layout
    LayoutModule,
    DialogsModule,
    MatNativeDateModule,
    ServiceWorkerModule.register('sw.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000'
    }),
    IntercomModule.forRoot({
      appId: environment.IntercomID,
      updateOnRouterChange: true
    }),
    MaintenanceModule
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
