import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'dialog-title',
  templateUrl: './dialog-title.component.html',
  styleUrls: ['./dialog-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogTitleComponent {
  @Input() title: string;
}
