<div class="content-layout fullwidth-basic-normal-scroll">
  <treo-card class="auth-card classic">
    <div class="form-container text-center">
      <div>
        <img [src]="'assets/images/quiz/intro.svg'" class="object-contain h-32 w-full" />
        <div *ngIf="blockAccess" class="text-center font-bold text-xl mt-4">
          En cours de mise à jour du système - retour en activité prévue dans 12h. En cas de besoin, contactez nous à l’adresse support@hoopcare.com
        </div>
        <div *ngIf="redirect && !blockAccess" class="text-center flex-column align-items-center">
         <div class="font-bold text-xl mt-4">
           La version 2 de l’application est arrivée.
         </div>
          <div class="font-normal text-lg text-black-500 mt-4">
            Améliorer l’évaluation pour réduire les complications grâce à de nouvelles fonctionnalités.
          </div>
          <div class="flex-row justify-content-center mt-4">
            <a mat-button color="warn" style="text-decoration: none" href="https://pro.hoopcare.com">Je suis un docteur</a>
            <a mat-button color="primary" style="text-decoration: none" (click)="redirectPatient()">Je suis un patient</a>
          </div>
        </div>
      </div>
    </div>
  </treo-card>
</div>
