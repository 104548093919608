import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { map, takeUntil } from 'rxjs/operators';
import { Hospital } from '~core/model2/domain/hospital';
import { HospitalService } from '~core/services/hospital.service';
import { AutoUnsubscribeComponent } from '~shared/auto-unsubscribe/auto-unsubscribe.component';

@Component({
  selector: 'hospital-select-dialog',
  templateUrl: './hospital-select-dialog.component.html',
  styleUrls: ['./hospital-select-dialog.component.scss']
})
export class HospitalSelectDialogComponent extends AutoUnsubscribeComponent implements OnInit {
  hospitals: Hospital[] = [];

  constructor(public dialogRef: MatDialogRef<any>, private hospitalService: HospitalService) {
    super();
  }

  ngOnInit(): void {
    this.hospitalService
      .getAllHospitals()
      .pipe(
        map((arr) => arr.filter((h) => h.uid === 'hoopcare')),
        takeUntil(this.destroy$)
      )
      .subscribe((hospitals) => {
        this.hospitals = hospitals;
      });
  }

  onLogoClick(hospitalId: string): void {
    this.dialogRef.close(hospitalId);
  }
}
