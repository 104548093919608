import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { TreoMediaWatcherService } from '~treo/services/media-watcher';
import { TreoNavigationItem, TreoNavigationService } from '~treo/components/navigation';
import { AuthService } from '~core/services/auth.service';
import { AutoUnsubscribeComponent } from '~shared/auto-unsubscribe/auto-unsubscribe.component';
import { LanguageService } from '~core/services/language.service';
import { doctorNavigation, patientNavigation } from '~layout/navigation';
import { getLanguage, Language, LanguageCode, languagesList } from '~core/model2/language';
import { User, UserType } from '~core/model2/domain/user';

@Component({
  selector: 'classy-layout',
  templateUrl: './classy.component.html',
  styleUrls: ['./classy.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClassyLayoutComponent extends AutoUnsubscribeComponent implements OnInit {
  user: User;
  username: string;
  isScreenSmall: boolean;
  selectedLanguage: Language;
  languages = languagesList;
  navigationItems: TreoNavigationItem[] = [];

  private translatedNavigationItems: Map<LanguageCode, TreoNavigationItem[]>;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private authService: AuthService,
    private _treoMediaWatcherService: TreoMediaWatcherService,
    private _treoNavigationService: TreoNavigationService,
    private languageService: LanguageService,
    private changeDetector: ChangeDetectorRef,
    private translateService: TranslateService
  ) {
    super();
    this.translatedNavigationItems = new Map<LanguageCode, TreoNavigationItem[]>();
  }

  ngOnInit(): void {
    this.authService
      .getUser$()
      .pipe(
        filter((user: User) => !!user && !!user.type),
        takeUntil(this.destroy$)
      )
      .subscribe((user: User) => {
        this.user = user;
        this.username = `${user.firstName} ${user.lastName}`;
        this.setLanguage(getLanguage(user.languagePreference || LanguageCode.FR));
        this.navigationItems = this.getTranslatedNavigationItems(user.type);
        this.changeDetector.detectChanges();
      });

    this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.navigationItems = this.getTranslatedNavigationItems(this.user?.type || UserType.PATIENT);
      this.changeDetector.detectChanges();
    });

    // Subscribe to media changes
    this._treoMediaWatcherService.onMediaChange$.pipe(takeUntil(this.destroy$)).subscribe(({ matchingAliases }) => {
      // Check if the breakpoint is 'lt-md'
      this.isScreenSmall = matchingAliases[0] === 'md' || matchingAliases.includes('lt-md');
    });
  }

  toggleNavigation(key: string): void {
    // Get the navigation
    const navigation = this._treoNavigationService.getComponent(key);

    if (navigation) {
      // Toggle the opened status
      navigation.toggle();
    }
  }

  setLanguage(lang: Language): void {
    this.setLanguageWithoutSavingToDb(lang);
    this.languageService.saveUserLanguagePreference(this.user.uid, lang);
  }

  private setLanguageWithoutSavingToDb(lang: Language): void {
    this.selectedLanguage = lang;
    this.languageService.updateCurrentLanguage(lang);
  }

  private getTranslatedNavigationItems(userType: UserType): TreoNavigationItem[] {
    const currentLang = this.languageService.findLanguageCodeByValue(this.translateService.currentLang);
    if (this.translatedNavigationItems.has(currentLang)) {
      return this.translatedNavigationItems.get(currentLang);
    }

    const translatedItems = this.translateNavigationItems(
      userType === UserType.DOCTOR ? doctorNavigation : patientNavigation
    );

    this.translatedNavigationItems.set(currentLang, translatedItems);
    return translatedItems;
  }

  private translateNavigationItems(items: TreoNavigationItem[]): TreoNavigationItem[] {
    const recursivelyTranslate = (itemsToTranslate: TreoNavigationItem[]): TreoNavigationItem[] => {
      for (const item of itemsToTranslate) {
        item.title = this.translateService.instant(item.translate || item.title);

        if (item.children?.length) {
          item.children = recursivelyTranslate(item.children);
        }
      }

      return itemsToTranslate;
    };

    return recursivelyTranslate(cloneDeep(items));
  }
}
