import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { PatientDocument } from '../model2/patient-document';

@Injectable({
  providedIn: 'root'
})
export class ImageViewService {
  readonly displayImageChanged: Subject<PatientDocument> = new Subject<PatientDocument>();
}
