import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InputDialogData } from '../../model/dialog-data-types';

@Component({
  selector: 'input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.scss']
})
export class InputDialogComponent {
  inputValue = '';
  showPassword: boolean;

  readonly title: string;
  readonly message: string;
  readonly okButtonText: string;
  readonly cancelButtonText: string;
  readonly forPassword: boolean;
  readonly allowEmpty: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) private data: InputDialogData) {
    this.title = data?.title;
    this.message = data?.message;
    this.okButtonText = data?.okButtonText || 'UTIL.OK';
    this.cancelButtonText = data?.cancelButtonText || 'UTIL.CANCEL';
    this.forPassword = data?.forPassword || false;
    this.allowEmpty = !!data?.allowEmpty;
  }
}
