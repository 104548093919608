import { DateType } from '../model2/date-type';

export const parseDate = (d: DateType): Date | null => {
  if (!d) {
    return null;
  }

  if (d instanceof Date) {
    return d;
  }

  if (typeof d === 'number' || typeof d === 'string') {
    return new Date(d);
  }

  return d.toDate();
};
