export enum LanguageCode {
  FR = 'fr',
  EN = 'en',
  ES = 'es'
}

export class Language {
  code: LanguageCode;
  title: string;
  flag: string;
}

export const languagesMap = new Map<LanguageCode, Language>([
  [
    LanguageCode.FR,
    {
      code: LanguageCode.FR,
      title: 'Français',
      flag: 'fr'
    }
  ],
  [
    LanguageCode.EN,
    {
      code: LanguageCode.EN,
      title: 'English',
      flag: 'us'
    }
  ],
  [
    LanguageCode.ES,
    {
      code: LanguageCode.ES,
      title: 'Espanol',
      flag: 'es'
    }
  ]
]);

export const languagesList = Array.from(languagesMap.values());

export function getLanguage(lang: LanguageCode | string): Language {
  if (!lang) {
    throw new Error(lang);
  }

  if (typeof lang === 'string') {
    lang = lang.toLowerCase();
  }

  const l = languagesMap.get(lang as LanguageCode);
  if (!l) {
    throw new Error('Unknown language: ' + lang);
  }

  return l;
}
