import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogType, GenericDialogData } from '../../model/dialog-data-types';

@Component({
  selector: 'generic-dialog',
  templateUrl: './generic-dialog.component.html',
  styleUrls: ['./generic-dialog.component.scss']
})
export class GenericDialogComponent {
  title: string;
  link?: string;
  message: string;
  buttonText: string;
  type: DialogType;

  constructor(@Inject(MAT_DIALOG_DATA) public data: GenericDialogData) {
    this.title = data?.title;
    this.message = data?.message;
    this.link = data?.link;
    this.buttonText = data?.buttonText || 'UTIL.OK';
    this.type = data?.type || DialogType.INFO;
  }
}
