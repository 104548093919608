import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate, UpdateAvailableEvent } from '@angular/service-worker';
import { first } from 'rxjs/operators';
import { concat, interval, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApplicationUpdateService {
  private static readonly CHECK_INTERVAL = 5 * 60 * 1000; // every 5 minutes
  private static serviceRunning = false;

  constructor(private appRef: ApplicationRef, private updates: SwUpdate) {
    if (!ApplicationUpdateService.serviceRunning && updates.isEnabled) {
      ApplicationUpdateService.serviceRunning = true;

      const appStable = appRef.isStable.pipe(first((stable) => stable));
      const checkInterval = interval(ApplicationUpdateService.CHECK_INTERVAL);
      const checkForUpdatesInterval = concat(appStable, checkInterval);
      checkForUpdatesInterval.subscribe(() => updates.checkForUpdate());
    }
  }

  public availableUpdates(): Observable<UpdateAvailableEvent> {
    return this.updates.available;
  }

  public activateUpdate(): Promise<void> {
    return this.updates.activateUpdate();
  }
}
