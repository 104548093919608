import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { map, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Params } from '@angular/router';
import { TreoAnimations } from '~treo/animations';
import { MaintenanceService } from '~core/services/maintenance.service';
import { AutoUnsubscribeComponent } from '~shared/auto-unsubscribe/auto-unsubscribe.component';
import { Hospital } from '~core/model2/domain/hospital';
import { HospitalService } from '~core/services/hospital.service';

@Component({
  selector: 'maintenance-overlay',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: TreoAnimations
})
export class MaintenanceComponent extends AutoUnsubscribeComponent implements OnInit {
  blockAccess = false;
  redirect = false;

  constructor(private service: MaintenanceService, private hospitalService: HospitalService) {
    super();
  }

  ngOnInit(): void {
    this.service
      .watchV2MigrationFields()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.blockAccess = !!data.blockAccess;
        this.redirect = !!data.redirectToV2;
      });
  }

  redirectPatient(): void {
    const url = 'https://patient.hoopcare.com/auth/register';
    let loc = location.pathname;
    if (loc.endsWith('/')) {
      loc = loc.substring(0, loc.length - 1);
    }
    const hospitalId = loc.substring(loc.lastIndexOf('/') + 1);

    this.hospitalService
      .getHospitalById(hospitalId)
      .then((hospital: Hospital) => hospital.hospitalId)
      .catch(() => '')
      .then((identifier) => {
        window.location.replace(url + '/' + identifier);
      });
  }
}
