import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatNativeDateModule } from '@angular/material/core';
import { ImageViewerOverlayComponent } from './components/image-viewer/image-viewer-overlay.component';
import { GsPathImagePipe } from './pipes/gs-path-image.pipe';
import { GsImageResolverDirective } from './directives/gs-image-resolver.directive';
import { TreoCardModule } from '~treo/components/card';

@NgModule({
  declarations: [ImageViewerOverlayComponent, GsPathImagePipe, GsImageResolverDirective],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    TranslateModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    RouterModule,
    MatDividerModule,
    MatMenuModule,
    MatSnackBarModule,
    MatDatepickerModule,
    TreoCardModule,
    MatNativeDateModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatDatepickerModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatSelectModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    TreoCardModule,
    ImageViewerOverlayComponent,
    GsPathImagePipe,
    GsImageResolverDirective,
    MatNativeDateModule
  ]
})
export class SharedModule {}
