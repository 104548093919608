import { NativeDateAdapter } from '@angular/material/core';
import { Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { LanguageService } from '../services/language.service';

export const CUSTOM_DATE_FORMATS = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' }
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' }
  }
};
@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  constructor(platform: Platform, private languageService: LanguageService) {
    // eslint-disable-next-line rxjs/no-subject-value
    super(languageService.currentLanguageCode.getValue(), platform);
  }

  parse(value: string): Date | null {
    return this.languageService.parseDate(value);
  }

  format(date: Date): string {
    return this.languageService.formatLocaleDate(date);
  }
}
