import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialogData, DialogType } from '../../model/dialog-data-types';

@Component({
  selector: 'confirm-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {
  title: string;
  message: string;
  confirmButtonText: string;
  cancelButtonText: string;
  type: DialogType;

  constructor(@Inject(MAT_DIALOG_DATA) private data: ConfirmationDialogData) {
    this.title = data?.title || '';
    this.message = data?.message;
    this.confirmButtonText = data?.confirmButtonText || 'UTIL.OK';
    this.cancelButtonText = data?.cancelButtonText || 'UTIL.CANCEL';
    this.type = data?.type || DialogType.INFO;
  }

  getButtonBgClasses(): string {
    if (this.type === DialogType.ERROR || this.type === DialogType.WARNING) {
      return 'bg-red-600 hover:bg-red-700 focus:ring-red-500';
    }

    return 'bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500';
  }
}
