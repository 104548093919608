<app-dialog-container>
  <div>
    <dialog-icon [type]="type"></dialog-icon>
    <div class="mt-3 text-center sm:mt-5">
      <dialog-title [title]="title"></dialog-title>
      <dialog-message [link]="link" [message]="message"></dialog-message>
    </div>
  </div>
  <div class="mt-5 sm:mt-6 text-center">
    <button
      [mat-dialog-close]="true"
      class="inline-flex justify-center rounded-md border border-transparent shadow-sm px-8 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
    >
      {{ buttonText | translate }}
    </button>
  </div>
</app-dialog-container>
