import { Component, OnInit, Inject, ChangeDetectorRef, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as firebase from 'firebase';
import { WindowService } from '~core/services/window.service';
import { OtpDialogData } from '~dialogs/model/dialog-data-types';
import { SentryService } from '~core/services/sentry.service';

@Component({
  selector: 'otp-dialog',
  templateUrl: './otp-authentication-dialog.component.html',
  styleUrls: ['./otp-authentication-dialog.component.scss']
})
export class OtpAuthenticationDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('recaptchaContainer') recaptchaContainer: ElementRef;

  verificationCodeForm: FormGroup;
  loading = true;
  recaptchaSolved = false;

  private windowRef: Window & Record<string, any>;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: OtpDialogData,
    private formBuilder: FormBuilder,
    private windowService: WindowService,
    private changeDetector: ChangeDetectorRef,
    private sentryService: SentryService
  ) {}

  ngOnInit(): void {
    this.verificationCodeForm = this.formBuilder.group({
      verificationCode: ['', Validators.required]
    });
  }

  ngAfterViewInit(): void {
    this.windowRef = this.windowService.windowRef;
    this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(this.recaptchaContainer.nativeElement, {
      size: 'invisible',
      callback: () => {
        this.recaptchaSolved = true;
        this.loading = false;
        this.verificationCodeForm.controls.verificationCode.reset();
        this.changeDetector.detectChanges();
      }
    });
    this.sendLoginCode();
  }

  sendLoginCode(): void {
    this.loading = true;
    const appVerifier = this.windowRef.recaptchaVerifier;
    const provider = new firebase.auth.PhoneAuthProvider();
    provider
      .verifyPhoneNumber(this.data.phoneNumber, appVerifier)
      .then((result) => {
        this.windowRef.confirmationResult = result;
      })
      .catch((error) => {
        this.loading = false;
        this.sentryService.captureException(error);
        throw error;
      })
      .finally(() => this.changeDetector.detectChanges());
  }

  verifyOtp(): void {
    const verificationCode = this.verificationCodeForm.value.verificationCode;
    const phoneCredential = firebase.auth.PhoneAuthProvider.credential(
      this.windowRef.confirmationResult,
      verificationCode
    );
    const user = firebase.auth().currentUser;
    user
      .updatePhoneNumber(phoneCredential)
      .then(() => this.confirm(true))
      .catch((error) => {
        let errorMessage = 'ERROR.UNEXPECTED';
        if (error?.code === 'auth/invalid-verification-code') {
          // @TODO translations
          errorMessage = "La réauthentification a échoué. Veuillez saisir les informations d'identification correctes";
        } else {
          this.sentryService.captureException(error);
        }

        this.confirm(new Error(errorMessage));
      });
  }

  resendOtp(): void {
    this.loading = true;
    this.changeDetector.detectChanges();
    this.verificationCodeForm.controls.verificationCode.setValue('');
    this.windowRef.recaptchaVerifier.reset();
    const appVerifier = this.windowRef.recaptchaVerifier;
    appVerifier
      .verify()
      .then(() => {
        this.sendLoginCode();
      })
      .catch((error) => {
        this.confirm(error instanceof Error ? error : new error(error));
      });
  }

  confirm(result: boolean | Error): void {
    this.dialogRef.close(result);
  }
}
