import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/overlay';
import { GenericDialogComponent } from '../components/generic-dialog/generic-dialog.component';
import {
  ConfirmationDialogData,
  DialogType,
  InputDialogData,
  OtpDialogData,
  ReauthenticateDialogData,
  GenericDialogData
} from '../model/dialog-data-types';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';
import { InputDialogComponent } from '../components/input-dialog/input-dialog.component';
import { ReauthenticateDialogComponent } from '~dialogs/components/reauthenticate-dialog/reauthenticate-dialog.component';
import { OtpAuthenticationDialogComponent } from '~dialogs/components/otp-authentication-dialog/otp-authentication-dialog.component';
import { PasswordUpdateDialogComponent } from '~dialogs/components/password-update-dialog/password-update-dialog.component';
import { HospitalSelectDialogComponent } from '~dialogs/components/hospital-select-dialog/hospital-select-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  /**
   * Constructor
   *
   * @param dialog
   */
  constructor(public dialog: MatDialog) {}

  showErrorDialog(data?: { title?: string; message?: string; buttonText?: string; link?: string }): Promise<void> {
    const dialogData: GenericDialogData = {
      type: DialogType.ERROR,
      title: data?.title || 'Oops!',
      message: data?.message || 'UTIL.GENERIC_ERROR',
      buttonText: data?.buttonText || 'UTIL.GO_BACK',
      link: data?.link
    };
    const options = {
      panelClass: 'no-padding-dialog',
      data: dialogData
    };
    return this.displayModal(GenericDialogComponent, options);
  }

  showSuccessDialog(data: { title?: string; message: string; buttonText?: string }): Promise<void> {
    const dialogData: GenericDialogData = {
      type: DialogType.SUCCESS,
      title: data.title || 'UTIL.SUCCESS',
      message: data.message,
      buttonText: data.buttonText
    };
    const options = {
      panelClass: 'no-padding-dialog',
      data: dialogData
    };
    return this.displayModal(GenericDialogComponent, options);
  }

  showInfoDialog(data: { title?: string; message: string; buttonText?: string }): Promise<void> {
    const dialogData: GenericDialogData = {
      type: DialogType.INFO,
      title: data.title,
      message: data.message,
      buttonText: data.buttonText
    };
    const options = {
      panelClass: 'no-padding-dialog',
      data: dialogData
    };
    return this.displayModal(GenericDialogComponent, options);
  }

  showConfirmationDialog(data: ConfirmationDialogData): Promise<boolean> {
    const options = {
      panelClass: 'no-padding-dialog',
      data: data
    };

    return this.displayModal(ConfirmationDialogComponent, options);
  }

  showInputDialog(data: InputDialogData): Promise<string | null> {
    const options = {
      panelClass: 'no-padding-dialog',
      data: data
    };

    return this.displayModal(InputDialogComponent, options);
  }

  showReauthenticateDialog(data: ReauthenticateDialogData): Promise<boolean> {
    const options = {
      panelClass: 'no-padding-dialog',
      data: data
    };

    return this.displayModal(ReauthenticateDialogComponent, options);
  }

  showOtpDialog(data: OtpDialogData): Promise<boolean> {
    const options = {
      panelClass: 'no-padding-dialog',
      data: data
    };

    return this.displayModal(OtpAuthenticationDialogComponent, options);
  }

  showPasswordUpdateDialog(): Promise<boolean | string> {
    const options = {
      panelClass: 'no-padding-dialog'
    };
    return this.displayModal(PasswordUpdateDialogComponent, options);
  }

  showHospitalSelectDialog(): Promise<string> {
    const options = {
      panelClass: 'no-padding-dialog',
      disableClose: true,
      closeOnNavigation: true
    };
    return this.displayModal(HospitalSelectDialogComponent, options);
  }

  closeAll(): void {
    if (this.dialog.openDialogs.length) {
      this.dialog.closeAll();
    }
  }

  /**
   * show diplay modal
   * @param component
   * @param options
   */
  private displayModal(component: ComponentType<unknown>, options: MatDialogConfig): Promise<any> {
    if (this.dialog.openDialogs.length) {
      this.dialog.closeAll();
    }
    return this.dialog.open(component, options).afterClosed().toPromise();
  }
}
