import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrintQueryParams } from 'app//print/Printable';

@Component({
  selector: 'print-layout',
  templateUrl: './print-layout.component.html',
  styleUrls: ['./print-layout.component.scss']
})
export class PrintLayoutComponent implements OnInit {
  params: PrintQueryParams = {};

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    const params = this.route.snapshot.queryParams;
    this.params = {
      withHeader: this.parseBooleanHeader(params.withHeader),
      withFooter: this.parseBooleanHeader(params.withFooter),
      footerText: params.footerText,
      headerText: params.headerText,
      headerLogo: this.parseBooleanHeader(params.headerLogo),
      headerHeight: params.headerHeight
    };
  }

  private parseBooleanHeader = (param) => param && /true|1|on/.test(param);
}
