import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { AuthService } from '~core/services/auth.service';
import { User } from '~core/model2/domain/user';

@Injectable({
  providedIn: 'root'
})
export class IsDoctorGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean> | boolean {
    return this.check();
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.check();
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean> | boolean {
    return this.check();
  }

  private check(): Observable<boolean | UrlTree> {
    return this.authService.getUser$().pipe(
      filter((user: User) => !!user),
      take(1),
      map((user: User) => {
        if (!!user?.uid && user.isPatient()) {
          return this.router.createUrlTree(['/patient']);
        }

        return true;
      })
    );
  }
}
