import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MaintenanceComponent } from './maintenance.component';
import { SharedModule } from '~shared/shared.module';

const routes: Routes = [
  {
    path: '',
    component: MaintenanceComponent
  }
];

@NgModule({
  declarations: [MaintenanceComponent],
  imports: [SharedModule, RouterModule.forChild(routes)],
  exports: [MaintenanceComponent]
})
export class MaintenanceModule {}
