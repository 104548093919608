<app-dialog-container>
  <div class="flex items-start">
    <dialog-icon [type]="type"></dialog-icon>
    <div class="ml-6 text-center">
      <div class="mt-0 mb-6">
        <dialog-title [title]="title"></dialog-title>
      </div>
      <dialog-message [message]="message"></dialog-message>
    </div>
  </div>
  <div class="mt-6 flex justify-end">
    <button
      [mat-dialog-close]="true"
      class="inline-flex mr-2 justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm"
      [ngClass]="getButtonBgClasses()"
    >
      {{ confirmButtonText | translate }}
    </button>
    <button
      [mat-dialog-close]="false"
      class="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
    >
      {{ cancelButtonText | translate }}
    </button>
  </div>
</app-dialog-container>
