import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { User } from '~core/model2/domain/user';

@Injectable({
  providedIn: 'root'
})
export class RootRedirectionGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean> | boolean {
    return this.authService.getUser$().pipe(
      take(1),
      map((user: User) => this.redirectByUserType(user))
    );
  }

  private redirectByUserType(user: User): UrlTree {
    const redirectUrl = !user || !user.uid ? '/login' : user.isDoctor() ? '/doctor' : '/patient';

    return this.router.createUrlTree([redirectUrl], {
      replaceUrl: true
    });
  }
}
