import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConnectivityCheckerService {
  public online: BehaviorSubject<boolean>;

  constructor() {
    this.online = new BehaviorSubject<boolean>(navigator?.onLine || true);
    fromEvent(window, 'offline').subscribe(() => this.online.next(false));
    fromEvent(window, 'online').subscribe(() => this.online.next(true));
  }
}
