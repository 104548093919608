import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentData, DocumentSnapshot } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Hospital } from '../model2/domain/hospital';
import { FirestoreCollections } from './firestore-collections';

@Injectable({
  providedIn: 'root'
})
export class HospitalService {
  constructor(private afs: AngularFirestore) {}

  getHospitalById(id: string): Promise<Hospital> {
    return this.afs
      .collection(FirestoreCollections.HOSPITALS)
      .doc(id)
      .ref.get()
      .then((doc: DocumentSnapshot<DocumentData>) => {
        if (doc.exists) {
          return doc.data();
        }
        // @TODO translate error
        return Promise.reject(new Error(`Hospital with id '${id}' not found`));
      })
      .then((data: DocumentData) => ({
        finess: data.FINESS,
        frenchDepartment: data.FrenchDepartement,
        hospitalId: data.HospitalID,
        logo: data.Logo,
        name: data.Name
      }));
  }

  getHospitalBySpeUrl(urlId: string): Promise<{ hospitalId: string; type: string }> {
    return this.afs
      .collection(FirestoreCollections.SPECIAL_LINKS)
      .doc(urlId)
      .ref.get()
      .then((doc: DocumentSnapshot<DocumentData>) => {
        if (doc.exists) {
          return doc.data();
        }

        // @TODO translate error
        return Promise.reject(new Error(`Document not found for url: ${urlId}`));
      })
      .then((data) => ({
        hospitalId: data.Hospital,
        type: data.Type
      }));
  }

  getAllHospitals(): Observable<Hospital[]> {
    return this.afs
      .collection(FirestoreCollections.HOSPITALS)
      .snapshotChanges()
      .pipe(
        map((data) =>
          data
            .map((documentData) => documentData.payload.doc)
            .map((doc) => [doc.id, doc.data()])
            .map(([hospUid, hospData]: [string, any]) => ({
              finess: hospData.FINESS,
              frenchDepartment: hospData.FrenchDepartement,
              hospitalId: hospData.HospitalID,
              logo: hospData.Logo,
              name: hospData.Name,
              uid: hospUid
            }))
        )
      );
  }
}
