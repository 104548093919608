<app-dialog-container>
  <div class="my-5 mx-5">
    <div class="text-center">
      <div class="mb-1">Si vous avez reçu un lien de connexion par email ou SMS, merci de l'utiliser pour vous inscrire.</div>
      <div>Si vous n'avez pas reçu de lien ou que vous souhaitez tester la solution, vous pouvez choisir HoopCare dans la liste ci-dessous.</div>
    </div>
  </div>
  <div class="my-3 flex flex-column justify-center items-center h-32">
    <mat-select class="w-75 m-auto h-16 hosp-select">
      <mat-option *ngFor="let hospital of hospitals" class="h-32">
        <div class="w-30 logo-btn" (click)="onLogoClick(hospital.uid)">
          <img [src]="hospital.logo" class="object-contain h-full w-full" />
        </div>
      </mat-option>
    </mat-select>
  </div>
</app-dialog-container>
