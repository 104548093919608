import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FirestoreCollections } from './firestore-collections';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {
  constructor(private firestore: AngularFirestore) {}

  public watchMaintenanceMode(): Observable<boolean> {
    return this.firestore
      .collection(FirestoreCollections.MAINTENANCE)
      .valueChanges()
      .pipe(
        map((docs: { currentMaintenance: boolean }[]) => {
          if (!docs.length) {
            return false;
          }

          return docs.some(({ currentMaintenance }) => !!currentMaintenance);
        }),
        catchError(() => of(false))
      );
  }

  public watchV2MigrationFields(): Observable<{ blockAccess: boolean; redirectToV2: boolean } | null> {
    return this.firestore
      .collection(FirestoreCollections.MAINTENANCE)
      .valueChanges()
      .pipe(
        map((docs: { v2Migration: any }[]) => {
          if (docs.length !== 1) {
            throw new Error();
          }

          return docs[0].v2Migration ?? null;
        }),
        catchError(() => of(null))
      );
  }
}
