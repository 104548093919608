export type GenericDialogData = {
  type: DialogType;
  message: string;
  title: string;
  buttonText?: string;
  link?: string;
};

export type ConfirmationDialogData = {
  title?: string;
  message: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  type?: DialogType;
};

export type InputDialogData = {
  title?: string;
  message?: string;
  okButtonText?: string;
  cancelButtonText?: string;
  forPassword?: boolean;
  allowEmpty?: boolean;
};

export type ReauthenticateDialogData = {
  email: string;
};

export type OtpDialogData = {
  phoneNumber: string;
};

export enum DialogType {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success'
}
