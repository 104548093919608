import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Router} from '@angular/router';
import {take} from 'rxjs/operators';
import {AuthService} from '~core/services/auth.service';

@Injectable()
export class TreoSplashScreenService {

  constructor(
      @Inject(DOCUMENT) private _document: any,
      private _router: Router,
      private authService: AuthService
  ) {
    this._init();
  }

  private _init(): void {
    this.authService.watchAuth()
        .pipe(take(1))
        .subscribe(() => this.hide());
  }

  show(): void {
    this._document.body.classList.remove('treo-splash-screen-hidden');
  }

  hide(): void {
    this._document.body.classList.add('treo-splash-screen-hidden');
  }
}
