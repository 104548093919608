<app-dialog-container>
  <div>
    <div class="mt-3 text-center sm:mt-5">
      <dialog-title [title]="title"></dialog-title>
      <dialog-message [message]="message"></dialog-message>
    </div>
  </div>
  <div class="mt-3 flex justify-center items-center">
    <mat-form-field appearance="fill" class="w-full">
      <input matInput [type]="forPassword && !showPassword ? 'password' : 'text'" [(ngModel)]="inputValue" />
      <button
        *ngIf="forPassword"
        mat-icon-button
        matSuffix
        (click)="showPassword = !showPassword"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="showPassword"
      >
        <mat-icon>{{ showPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div class="mt-6 flex justify-end">
    <button
      [mat-dialog-close]="inputValue"
     [disabled]="!allowEmpty && inputValue.length === 0" class="inline-flex mr-2 justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
    >
      {{ okButtonText | translate }}
    </button>
    <button
      [mat-dialog-close]="null"
      class="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
    >
      {{ cancelButtonText | translate }}
    </button>
  </div>
</app-dialog-container>
