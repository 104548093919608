import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { OtpAuthenticationDialogComponent } from './components/otp-authentication-dialog/otp-authentication-dialog.component';
import { ReauthenticateDialogComponent } from './components/reauthenticate-dialog/reauthenticate-dialog.component';
import { InputDialogComponent } from './components/input-dialog/input-dialog.component';
import { GenericDialogComponent } from './components/generic-dialog/generic-dialog.component';
import { DialogTitleComponent } from './shared/dialog-title/dialog-title.component';
import { DialogMessageComponent } from './shared/dialog-message/dialog-message.component';
import { DialogIconComponent } from './shared/dialog-icon/dialog-icon.component';
import { DialogContainerComponent } from './shared/dialog-container/dialog-container.component';
import { PasswordUpdateDialogComponent } from './components/password-update-dialog/password-update-dialog.component';
import { HospitalSelectDialogComponent } from './components/hospital-select-dialog/hospital-select-dialog.component';
import { SharedModule } from '~shared/shared.module';

@NgModule({
  declarations: [
    ConfirmationDialogComponent,
    OtpAuthenticationDialogComponent,
    ReauthenticateDialogComponent,
    InputDialogComponent,
    GenericDialogComponent,
    DialogTitleComponent,
    DialogMessageComponent,
    DialogIconComponent,
    DialogContainerComponent,
    PasswordUpdateDialogComponent,
    HospitalSelectDialogComponent
  ],
  imports: [SharedModule, MatDialogModule, MatProgressSpinnerModule],
  exports: []
})
export class DialogsModule {}
