<div class="mt-2">
  <p class="text-lg text-gray-500">
    <span *ngIf="!textualParts">
      <span>{{ message | translate }}</span>
    </span>
    <span *ngIf="!!textualParts">
      <span>{{ textualParts[0] }}</span>
      <span *ngIf="link" class="text-blue-600">
        <a [href]="link" target="_blank">{{ link }}</a>
      </span>
      <span *ngIf="textualParts[1]">{{ textualParts[1] }}</span>
    </span>
  </p>
</div>
