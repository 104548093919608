import { ChangeDetectorRef, Component, HostBinding, OnInit } from '@angular/core';
import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { takeUntil } from 'rxjs/operators';
import { MaintenanceService } from '~core/services/maintenance.service';
import { AutoUnsubscribeComponent } from '~shared/auto-unsubscribe/auto-unsubscribe.component';

@Component({
  selector: 'maintenance-alert',
  templateUrl: './maintenance-alert.component.html',
  styleUrls: ['./maintenance-alert.component.scss'],
  animations: [
    trigger('inOutAnim', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-1000px)' }),
        animate(
          '750ms 100ms cubic-bezier(0.680, -0.550, 0.265, 1.550)',
          keyframes([
            style({ transform: 'translateY(-1000px)', opacity: 0 }),
            style({ transform: 'translateY(0)', opacity: 1 })
          ])
        )
      ]),
      transition(':leave', [
        animate(
          '500ms cubic-bezier(0.680, -0.550, 0.265, 1.550)',
          keyframes([
            style({ transform: 'translateY(0)', opacity: 1 }),
            style({ transform: 'translateY(-1000px)', opacity: 0 })
          ])
        )
      ])
    ])
  ]
})
export class MaintenanceAlertComponent extends AutoUnsubscribeComponent implements OnInit {
  @HostBinding('class') hostClass = '';
  hidden: boolean;
  currentMaintenance: boolean;

  constructor(private maintenanceService: MaintenanceService, private changeDetector: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.maintenanceService
      .watchMaintenanceMode()
      .pipe(takeUntil(this.destroy$))
      .subscribe((currentState: boolean) => {
        this.currentMaintenance = currentState;
        if (currentState) {
          this.hidden = false;
          this.hostClass = '';
        } else {
          this.hostClass = 'hidden';
        }
        this.changeDetector.detectChanges();
      });
  }

  hideAlert(): void {
    this.hidden = true;
    setTimeout(() => {
      this.hostClass = 'hidden';
    }, 300);
  }

  goToContact(): void {
    window.open('https://www.ianesthesie.com/support', '_blank');
  }

  moreInfo(): void {
    this.goToContact();
  }
}
